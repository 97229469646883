<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dct, dctFormAvailable } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { isPresent } from 'utils/tools';
	import moment from 'moment';

	export let blockTitle = 'Ответы на отправленные уведомления и запросы';
	export let correspondentKinds;
	export let fieldId;
	export let newPage = false;

	let indexCollapse = false;
	let procedureId = null;
	let date;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = $procedure && $procedure.id;
	$: answers = ($transmitter && $transmitter.answers) || answers || [];
	$: if (correspondentKinds) {
		answers = answers.filter(answer => correspondentKinds.includes(answer.addressee_kind_code));
	}

	const answerItem = dct => {
		if (dct.kind === 'other') {
			return `${dct.additions.name}${isPresent(dct.content) ? ` ${dct.content}` : ''}`;
		} else {
			let name = dct.correspondent_full_name || dct.counterparty_name || dct.name || 'Неизвестный отправитель';
			return [dct.reply_date ? moment(dct.reply_date).format('DD.MM.YYYY') : null, name, dct.content].filter(Boolean).join(' ');
		}
	};

	const goToAnswer = answerId => {
		let answerFormUrl = answerId ? `/answers/${answerId}` : '/answers';
		if (correspondentKinds) {
			answerFormUrl = `${answerFormUrl}?correspondent_kinds=${correspondentKinds}`;
		}

		if (newPage) {
			window.open(answerFormUrl, '_blank');
		} else {
			location.href = answerFormUrl;
		}
	};

	const loadAnswers = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/answers/options_for_reports', {
				procedure_id: procedureId,
				correspondent_kinds: correspondentKinds,
				date: $dct.date,
			});
			$transmitter = { ...$transmitter, answers: response.answers };
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	$: if (!moment(date).isSame(moment($dct.date)) || (!$transmitter.answers && !fetching)) {
		date = $dct.date;
		loadAnswers();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="answers col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`${blockTitle} (${answers.length})`}</span>
	</h4>
</div>
<div id="collapse-answers" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={() => goToAnswer()}> + Входящий </button>
		<div class="p-1">
			{#if answers.length}
				<ol class="block-list">
					{#each answers as answer}
						<li>
							<div
								class="float-left"
								class:simulated-link={$dctFormAvailable}
								class:simulated-link-disabled={!$dctFormAvailable}
								role="button"
								tabindex="0"
								on:click={$dctFormAvailable && goToAnswer(answer.id)}
								on:keypress|stopPropagation
							>
								{answerItem(answer)}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>
