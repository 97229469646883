<!-- @format -->
<script>
	import { onMount, createEventDispatcher } from 'svelte';
	import iMask from 'imask';
	import 'tempusdominus-bootstrap-4';
	import '~/js/patch_for_datetimepicker';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import moment from 'moment';

	export let attribute;
	export let model;
	export let label;
	export let value = '';
	export let required = false;
	export let disabled = false;
	export let placeholder;
	export let expansionInFocus = false;
	export let widgetLeft = false;
	export let maskOptions;
	export let errors;
	export let startDate;
	export let hintData;
	export let labelCss = 'col-sm-4 col-lg-3 col-xl-4 col-form-label';
	export let divCss = 'col-sm-8 col-lg-9 col-xl-8';

	const dispatch = createEventDispatcher();

	let inputField;
	let groupField;
	let labelField;
	let datetimepickerNode;
	let mask;
	let newValue;

	$: if (!label) {
		divCss = 'col-12'
	}

	$: fValue = moment(value, 'YYYY-MM-DD').isValid() ? moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY') : moment(value, 'DD.MM.YYYY').isValid() ? value : '';
	$: isSuccess = value && !errors;
	$: value = moment(value, 'YYYY-MM-DD').isValid() ? value : moment(value, 'DD.MM.YYYY').isValid() ? moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD') : '';

	const updateMaskValue = () => {
		mask.updateValue(moment(fValue, 'DD.MM.YYYY').format('DD.MM.YYYY'));
	};

	onMount(() => {
		mask = iMask(inputField, maskOptions);
		mask.on('complete', () => updateMaskValue());
		// eslint-disable-next-line no-undef
		datetimepickerNode = jQuery(`#datepicker-${model}-${attribute}`);
		datetimepickerNode.datetimepicker({
			widgetPositioning: { horizontal: 'left', vertical: 'auto' },
			orientation: 'bottom left',
			locale: 'ru',
			format: 'DD.MM.YYYY',
			useCurrent: false,
			allowInputToggle: true,
			//defaultDate: startDate,
			minDate: startDate,
			keyBinds: {
				escape: null,
				up: null,
				down: null,
				right: null,
				left: null,
				delete: null,
			},
		});
		datetimepickerNode.on('change.datetimepicker', event => {
			const nValue = moment(event.date, 'YYYY-MM-DD').isValid()
				? event.date.format('YYYY-MM-DD')
				: moment(event.date, 'DD.MM.YYYY').isValid()
					? moment(event.date, 'DD.MM.YYYY').format('YYYY-MM-DD')
					: '';

			// когда дата вводится копипастом по нажатию на Enter происходят 2 события change,
			// одно непосредственно на изменение даты, второе при закрытии календаря, котором ничего не выбрано.
			// в результате поле не заполняется.
			// newValue получает значение при первом проходе, nValue при втором.
			if (newValue && ((nValue && newValue == nValue) || !nValue)) {
				newValue = ''; // наблюдать. не понятно поможет ли это при очистке поля во всех случаях или надо что-то ещё придумывать
				return;
			} else {
				newValue = nValue;
			}

			// событие порождаем здесь, пока не изменился value
			if (newValue != value) {
				dispatch('change', newValue);
			}

			value =
				(moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY') === fValue && value !== newValue) || !fValue
					? newValue
					: moment(fValue, 'DD.MM.YYYY').format('YYYY-MM-DD');
		});
		if (expansionInFocus) {
			datetimepickerNode.on('show.datetimepicker', () => {
				let dropdownMenuNode = groupField.querySelector('.dropdown-menu');

				if (/top/.test(dropdownMenuNode.classList)) {
					groupField.style.marginTop = '300px';
				} else {
					groupField.style.marginBottom = '300px';
				}
			});
			datetimepickerNode.on('hide.datetimepicker', () => {
				groupField.style.marginTop = '1rem';
				groupField.style.marginBottom = '1rem';
			});
		}
	});
</script>

<div class="row" class:form-group={label} class:has-error={errors} class:has-success={isSuccess} bind:this={groupField}>
	{#if label}
		<label for={`datepicker-${model}-${attribute}`} class={labelCss} bind:this={labelField}>
			{label}
			{#if required}<attr class:required>*</attr>{/if}
		</label>
	{/if}
	<div class={divCss}>
		<div
			id={`datepicker-${model}-${attribute}`}
			class="input-group date datetimepicker"
			class:widget-left={widgetLeft}
			data-target-input="nearest"
			style="padding-right: 15px;"
		>
			<input
				id={`input-${model}-${attribute}`}
				type="text"
				name={attribute}
				class:required-border={required}
				class:text-disabled={disabled}
				class="form-control datetimepicker-input"
				bind:value={fValue}
				bind:this={inputField}
				{disabled}
				on:focus={updateMaskValue}
				{placeholder}
				spellcheck="false"
				data-target={`#datepicker-${model}-${attribute}`}
				autocomplete="off"
			/>
			<div class="input-group-append" data-target={`#datepicker-${model}-${attribute}`} data-toggle="datetimepicker">
				<div class="input-group-text" style={disabled ? 'cursor: not-allowed;' : ''}>
					<iconify-icon icon="bx-bxs-calendar" style={disabled ? 'opacity: 0.6;' : ''} />
				</div>
			</div>
		</div>
		{#if errors}
			<InputErrorsString {errors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>

<style lang="scss">
	@media (max-width: 576px) {
		.datetimepicker {
			width: 247px;
		}
		#datepicker-undefined-date,
		#datepicker-requirements_reestr-date {
			width: 150px;
		}
	}
</style>
