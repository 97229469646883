<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { toArray, isBlank, isPresent } from 'utils/tools';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import InputMoney from '~/svelte/components/ui/input_money.svelte';
	import ScansSet from '~/svelte/_shared/scans_sets/_set_field.svelte'

	const dispatch = createEventDispatcher();

	export let isNewInstance
	export let disabled = false
	export let formOptions;
	export let isCompleted;
	export const submitForm = () => {
		if (!isNewInstance) {
			if ($form.kind == 'payable_collection') { $form.payer = null }
			if (['asset_disposal', 'other'].includes($form.kind)) { $form.receivable_id = null }
		}
		return formControl.submitForm()
	}
	export const deleteItem = () => formControl.deleteItem();
	export const getItem = () => formControl.getItem();
	export const setItem = changes => formControl.setItem(changes);
	export const getData = () => data;
	export const setData = changes => (data = { ...data, ...changes });

	let formControl;
	let form, errorsBase, handleChange;
	let data = {};

	$: if (isPresent($form?.id) && !$form.bank_account_id) {
		$form.bank_account_id = 'cash';
	}

	const setInitialazedForm = instance => {
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;
	};

	const setDictionariesValues = values => {
		data = { ...values };
		data.receivables = [{ value: -1, label: 'Добавить требование', action: true }, ...data.receivables];
		data.possessions = [{ value: -1, label: 'Добавить объект имущества', action: true }, ...data.possessions];
		data.bank_accounts = [{ value: -1, label: 'Добавить счёт', action: true }, { value: 'cash', label: 'Наличные', action: false }, ...data.bank_accounts];
	};

	const setSubmitParams = () => ({ bank_account_id: $form.bank_account_id == 'cash' ? null : $form.bank_account_id });

	const handleNewReceivable = () => dispatch('newReceivable');

	const handleNewPossession = () => dispatch('newPossession');

	const handleNewBankAccount = () => dispatch('newBankAccount');

	$: filledRequiredFields = $form &&
		isPresent($form.date_at) &&
		isPresent($form.bank_account_id) && Number.isInteger(+$form.bank_account_id) &&
		isPresent($form.num) &&
		isPresent($form.note) &&
		(isPresent($form.payer) || isPresent($form.receivable_id))

	let creating = false
	$: if (isBlank($form?.id) && filledRequiredFields) {
		if (!creating) { secretlyСreate() }
		creating = true
	}

	const secretlyСreate = async () => {
		const response = await submitForm()
		$form = response?.item
		creating = false
	}

	$: if ($form?.kind == 'asset_disposal' && $form?.possession_id) {
		const possession = data.possessions.find(p => +p.value == +$form.possession_id)

		for (let attribute of ['saleMethod', 'contractDate', 'contractNumber', 'costUnderContract']) {
			$form.extended_data[attribute] = $form.extended_data[attribute] || possession.extended_data[attribute]
		}

		$form.receivable_id = null
	}

	$: if ($form && $form?.kind != 'asset_disposal') {
		$form.possession_id = null
		$form.extended_data = {}
	}

	$: if (isPresent($form) && !isNewInstance) { isNewInstance = isBlank($form?.id) }
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	formOptions={{ ...formOptions, model: 'receipt', controller: 'receipts' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<!-- Дата поступления -->
	<Field name="date_at" label="Дата поступления" required={true}>
		<Datepicker
			required={true}
			model='receipt'
			attribute='date_at'
			placeholder='дд.мм.гггг'
			maskOptions={{ mask: Date }}
			{disabled}
			bind:value={$form.date_at}
		/>
	</Field>
	<!-- Сумма -->
	<Field name="sum" label="Сумма поступления (руб.)" short={true} required={true}>
		<InputMoney {disabled} name="sum" bind:value={$form.sum} klass="form-control required-border text-right" />
	</Field>
	<!-- Счёт, куда поступили деньги -->
	<Field name="bank_account_id" label="Счёт, куда поступили деньги" required={true}>
		<Select
			isDisabled={disabled}
			name="bank_account_id"
			items={data.bank_accounts}
			value={$form.bank_account_id}
			onchange={handleChange}
			on:action={handleNewBankAccount}
			containerClasses="form-control"
			placeholder="Выбрать счет"
		></Select>
	</Field>
	<!-- Вид поступления -->
	<Field name="kind" label="Вид поступления" required={true}>
		{#each toArray(data.kinds) as option}
			<label class="d-flex">
				<input {disabled} type="radio" bind:group={$form.kind} class="align-self-center m-r-xs" value={option.value} />
				{option.label}
			</label>
		{/each}
	</Field>

	{#if $form.kind == 'asset_disposal'}
		<!-- Объект имущества -->
		<Field name="possession_id" label="Объект имущества" required={true}>
			<Select
				isDisabled={disabled}
				name="possession_id"
				items={data.possessions}
				value={$form.possession_id}
				onchange={handleChange}
				on:action={handleNewPossession}
				containerClasses="form-control"
				placeholder="Выбрать объект имущества"
			></Select>
		</Field>
		<!-- Способ реализации -->
		<Field {disabled} name={['extended_data', 'saleMethod']} label="Способ реализации">
			<Select
				name="extended_data[saleMethod]"
				items={data.sale_methods || []}
				value={$form.extended_data['saleMethod']}
				onchange={handleChange}
				isDisabled={disabled}
				containerClasses="form-control"
				placeholder="Выбрать способ реализации"
			/>
		</Field>
		<!-- Дата договора -->
		<Datepicker
			model='receipt'
			attribute='extended_data-contractDate'
			label='Дата договора'
			placeholder='дд.мм.гггг'
			maskOptions={{ mask: Date }}
			{disabled}
			onchange={handleChange}
			bind:value={$form.extended_data['contractDate']}
		/>
		<!-- Номер договора -->
		<Field name={['extended_data', 'contractNumber']} label="Номер договора">
			<input
				{disabled}
				name="extended_data[contractNumber]"
				class="form-control"
				bind:value={$form.extended_data['contractNumber']}
				on:change={handleChange}
				placeholder="Введите номер договора"
			/>
		</Field>
		<!-- Стоимость по договору (руб.) -->
		<Field name={['extended_data', 'costUnderContract']} label="Стоимость по договору (руб.)" short={true}>
			<InputMoney {disabled} name="extended_data[costUnderContract]" bind:value={$form.extended_data['costUnderContract']} klass="form-control text-right" />
		</Field>
	{/if}

	{#if ['asset_disposal', 'bankrupt_salary', 'other'].includes($form.kind)}
		<!-- Плательщик -->
		<Field name="payer" label="Плательщик" required={true}>
			<input {disabled} name="payer" type="text" class="form-control" bind:value={$form.payer} on:change={handleChange} placeholder="ООО “Покупатель”" />
		</Field>
	{:else if $form.kind == 'payable_collection'}
		<!-- Требование к третьим лицам -->
		<Field name="receivable_id" label="Требование к третьим лицам" required={true}>
			<Select
				isDisabled={disabled}
				name="receivable_id"
				items={data.receivables}
				value={$form.receivable_id}
				onchange={handleChange}
				on:action={handleNewReceivable}
				containerClasses="form-control"
				placeholder="Выбрать требование"
			></Select>
		</Field>
	{/if}
	<!-- Номер документа -->
	<Field name="num" label="Номер документа" required={true}>
		<input {disabled} name="num" type="text" class="form-control" bind:value={$form.num} on:change={handleChange} placeholder="15" />
	</Field>
	<!-- Дата документа -->
	<Field name="num_at" label="Дата документа">
		<Datepicker
			model='receipt'
			attribute='num_at'
			placeholder='дд.мм.гггг'
			maskOptions={{ mask: Date }}
			{disabled}
			bind:value={$form.num_at}
		/>
	</Field>
	<!-- Назначение платежа -->
	<Field name="note" label="Назначение платежа" required={true}>
		<textarea {disabled} name="note" rows="3" class="form-control" bind:value={$form.note} on:change={handleChange} placeholder="Оплата имущества…" />
	</Field>
	<ScansSet label='Документы' scansSet={$form.scans_set} disabled={!filledRequiredFields} on:changed={({detail: scansSet}) => $form.scans_set = scansSet}/>
	<ErrorMessage message={ $errorsBase } on:click={() => $errorsBase = null}/>
</Form>
