<!-- @format -->
<script>
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatePeriod from '~/svelte/components/ui/autosave_date_period.svelte';

	$: if ($dct.form_fields.includes('reportingPeriod')) {
		$requiredFieldsFilled.reportingPeriod =
			reportingPeriodStartDate.period[0] && reportingPeriodStartDate.period[1];
	}

	$: reportingPeriodStartDate = {
		...$commonParams,
		required: true,
		attribute: 'reporting_period',
		label: 'с',
		labelAlign: 'right',
		period: $currentData?.reportingPeriod,
		index: 0,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData:
			moment($currentData.resolutionIntroductionDate).format('YYYY-MM-DD') == $currentData.reportingPeriod[0]
				? { linkText: 'AI заполнил из Процедура' }
				: { freeMessage: 'Изменено пользователем' },
	};

	$: reportingPeriodFinishDate = {
		...$commonParams,
		required: true,
		attribute: 'reporting_period',
		label: 'по',
		labelAlign: 'right',
		period: $currentData?.reportingPeriod,
		index: 1,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData:
			$currentData.date == $currentData.reportingPeriod[1]
				? { linkText: 'AI заполнил на основании Даты отчёта' }
				: { freeMessage: 'Изменено пользователем' },
	};
</script>

<div class="form-group row">
	<div class="col-sm-12">Отчётный период:</div>
</div>
<AutosaveDatePeriod {...reportingPeriodStartDate} />
<AutosaveDatePeriod {...reportingPeriodFinishDate} />
