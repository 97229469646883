<!-- @format -->
<script>
	import { transmitter, bankruptIsPerson } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch';
	import judicialActRecognizedHint from './judicial_act_recognized_hint';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';
	import AutosaveDadataInputFio from '~/svelte/components/ui/autosave_dadata_input_fio2.svelte';
	import AutosaveCheckbox from '~/svelte/components/ui/autosave_checkbox.svelte';
	import AutosaveInputCurrency from '~/svelte/components/ui/autosave_input_currency.svelte';
	import IncomeSourcesBlock from './income_sources/_list.svelte';

	let courtOptions = [];

	$: procedure = $transmitter?.procedure || procedure;
	$: procedureId = procedure?.id || null;
	$: arbitrCase = procedure?.arbitr_case;
	$: courtId = arbitrCase?.court_id;
	$: courtOption = courtId && courtOptions.find(option => parseInt(option.id) == parseInt(courtId));

	const getOptions = async () => {
		const response = await fetchGet('/api/private/courts/options');
		return [{ id: null, text: '', user_id: null }, ...response.options];
	};

	const refreshOptions = async () => (courtOptions = await getOptions());

	$: formFields = {
		court: {
			disabled: !(procedureId && $procedureAvailable),
			attribute: 'court_id',
			url: '/api/private/arbitr_cases',
			model: 'arbitr_case',
			id: arbitrCase?.id,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Наименование суда',
			options: courtOptions,
			placeholder: 'Искать или выбрать суд',
			value: courtOption,
		},
		refereeFullName: {
			disabled: !(procedureId && $procedureAvailable),
			model: 'procedure',
			attribute: 'referee_full_name',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'ФИО судьи',
			placeholder: 'Фамилия Имя Отчество',
			value: $transmitter && $transmitter.procedure && $transmitter.procedure.referee_full_name,
			hintData: {
				judicial_act_recognized: judicialActRecognizedHint({
					name: 'refereeFullName',
					value: $transmitter && $transmitter.procedure && $transmitter.procedure.referee_full_name,
				}),
			},
		},
		creationPlaceDocs: {
			disabled: !(procedureId && $procedureAvailable),
			attribute: 'creation_place_docs',
			url: '/api/private/procedures',
			model: 'procedure',
			id: procedureId,
			value: procedure?.creation_place_docs,
			label: 'Место составления документов',
			placeholder: 'г. Королёв',
		},
		dctsNumberPrefix: {
			disabled: !(procedureId && $procedureAvailable),
			attribute: 'dcts_number_prefix',
			url: '/api/private/procedures',
			model: 'procedure',
			id: procedureId,
			value: procedure?.dcts_number_prefix,
			label: 'Префикс номеров документов',
			placeholder: 'Введите префикс номеров документов',
		},
		bankruptHasConstantSourceOfIncome: {
			disabled: !(procedureId && $procedureAvailable),
			attribute: 'additions.bankrupt_has_constant_source_of_income',
			url: '/api/private/procedures',
			model: 'procedure',
			id: procedureId,
			value: procedure.additions.bankrupt_has_constant_source_of_income,
			label: 'Должник имеет постоянный источник дохода',
			text: 'Да',
		},
		livingWageForBankrupt: {
			disabled: !(procedureId && $procedureAvailable),
			attribute: 'additions.living_wage_for_bankrupt',
			url: '/api/private/procedures',
			model: 'procedure',
			id: procedureId,
			value: procedure.additions.living_wage_for_bankrupt,
			label: 'Прожиточный минимум для должника (руб.)',
			placeholder: '0,00',
		},
		livingWageForChildren: {
			disabled: !(procedureId && $procedureAvailable),
			attribute: 'additions.living_wage_for_children',
			url: '/api/private/procedures',
			model: 'procedure',
			id: procedureId,
			value: procedure.additions.living_wage_for_children,
			label: 'Прожиточный минимум для детей (руб.)',
			placeholder: '0,00',
		},
	};

	onMount(async () => await refreshOptions());
</script>

<div class="m-b-sm overlay procedure__additional-data-fields">
	<h3>{`${procedure?.phase == 'debt_restructuring' ? 7 : 8}. Данные для документов`}</h3>
	<AutosaveSelect2Search {...formFields.court} />
	<AutosaveDadataInputFio {...formFields.refereeFullName} />
	<AutosaveInput {...formFields.creationPlaceDocs} />
	<AutosaveInput {...formFields.dctsNumberPrefix} />
	{#if procedure?.id && $bankruptIsPerson}
		<AutosaveCheckbox {...formFields.bankruptHasConstantSourceOfIncome} />
		<IncomeSourcesBlock />
		<AutosaveInputCurrency {...formFields.livingWageForBankrupt} />
		<AutosaveInputCurrency {...formFields.livingWageForChildren} />
	{/if}
</div>
