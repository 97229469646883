<!-- @format -->
<script>
	import { procedure, options } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch';
	import { showModal } from '../components/modal.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import Header from '../components/header.svelte';
	import Table from './table.svelte';

	let relevanceDate;
	let procedureId = null;
	let mounted = false;
	let bankAccountId;

	const openModalForm = () => {
		bankAccountId = null;
		showModal('form-bank_account-modal');
	};

	$: currentBalanceOnDate = {
		attribute: 'date',
		disabled: false,
		label: 'Текущий остаток на',
		model: 'bank_account',
		widgetLeft: true,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		labelCss: 'col-form-label p-r-none pl-0 pl-sm-3 text-nowrap',
		divCss: 'col-sm-5 col-lg-4 col-xl-5 pl-0 pl-sm-3',
	};

	onMount(async () => {
		procedureId = $procedure && $procedure.id;

		relevanceDate = moment().format('YYYY-MM-DD');

		let savedRelevanceDate = localStorage.getItem('relevanceDate');

		if (savedRelevanceDate) {
			savedRelevanceDate = JSON.parse(savedRelevanceDate);
			relevanceDate = moment().isAfter(savedRelevanceDate.expire) ? moment().format('YYYY-MM-DD') : savedRelevanceDate.value;
		}

		const response = await fetchGet('/api/private/bank_accounts/kind_options');
		$options = { ...$options, bankAccountKind: response.options };
		mounted = true;
	});
</script>

<Header><span slot="name">Счета</span></Header>
<div class='instances-dashboard flex-slot m-b-sm'>
	<div class='mb-0 add-instance-btn'>
		<button
			class='btn btn-sm btn-warning m-r-sm m-b-sm mb-0 ml-2 ml-sm-0'
			disabled={!$procedureAvailable || !procedureId}
			on:click={procedureId && $procedureAvailable && openModalForm()}
		>
			+ Счёт
		</button>
	</div>
	<ul class="nav navbar-top-links tools-bar justify-content-end mb-2 row">
		<li class='instances-date'>
			<Datepicker {...currentBalanceOnDate} bind:value={relevanceDate} />
		</li>
	</ul>
</div>
<div class="m-b-lg overlay p-1 footer-margin">
	<Table {bankAccountId} {relevanceDate} bind:mounted />
</div>

<style lang="scss">
	.instances-dashboard {
		width: 100%;
		margin-bottom: 0;
	}
	.instances-date {
		margin-bottom: -1rem;
	}
	.overlay {
		border-top-color: #fff;
	}
	@media (max-width: 576px) {
		.instances-date {
			width: 54%;
		}
		.add-instance-btn {
			display: flex;
			align-items: flex-end;
		}
		.navbar-top-links li {
			align-self: end;
		}
	}
</style>
