<!-- @format -->
<script>
	import { onMount, createEventDispatcher } from 'svelte';
	import { isPresent, isBlank, toArray, truncateString, formatSum, formatDate, randomString } from 'utils/tools';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import SearchBy from '~/svelte/components/ui/dadata/search_by.svelte';
	import InputMoney from '~/svelte/components/ui/input_money.svelte';
	import { bankruptIsPerson, bankruptIsOrganization, options } from 'base_stores';
	import ChainFields from './_chain_fields.svelte';
	import ScansSet from '~/svelte/_shared/scans_sets/_set_field.svelte'
	import IMask from 'imask';

	const dispatch = createEventDispatcher();

	export let isNewInstance
	export let disabled = false
	export let formOptions
	export let isCompleted
	export let initBlank = false
	export const submitForm = () => { collapseKind(); return formControl.submitForm() }
	export const deleteItem = () => formControl.deleteItem()
	export const getItem    = () => formControl.getItem()
	export const setItem    = changes => formControl.setItem(changes)
	export const getData    = () => data
	export const setData    = changes => data = { ...data, ...changes }

	let formControl
	let form, errorsBase, handleChange, processErrors
	let data = {}
	let kindsCodes = {}
	let firstKindOption
	let filledKinds
	let organizationInn;
	let mounted = false;

	const collapseKind = () => {
		let kindKeys = Object.keys($form).filter(k => /^kind_id\d+$/.test(k));
		if (isBlank(kindKeys)) {
			return;
		}

		$form.kind_id = $form[kindKeys.sort().slice(-1)[0]];
		// работает и без удаления ключей, но пока оставим для памяти
		//for (let k of kindKeys) { delete $form[k] }
	};

	const generateId = name => `input-${name}-${randomString()}`;

	const setInitialazedForm = instance => {
		firstKindOption = isPresent(data.kinds) && data.kinds.filter(k => !k.parent_id && !['debit', 'money'].includes(k.code))[0];
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;

		if (form && !form.kind_id) {
			form.kind_id = firstKindOption && firstKindOption.id;
			form.kind_id0 = form.kind_id;
		}
	};

	//$: if (isBlank(data.kinds) && isPresent($options.possessionKind)) { data.kinds = $options.possessionKind }

	const setDictionariesValues = values => {
		data = { ...data, ...values };
		currentCreditorsRequirementIds = $form.creditors_requirement_ids;

		if (isBlank(data.kinds)) {
			data.kinds = $options.possessionKind;
		}

		for (let kind of toArray(data.kinds)) {
			kindsCodes[String(kind.value)] = kind.code;
		}
	};

	const setSubmitParams = () => ({});

	let currentCreditorsRequirementIds;

	const namePlaceholders = {
		money: 'Номер счета, адрес банка',
		debit: 'Размер долга, наименование дебитора, содержание обязательства, основание возникновения',
	};

	const assignCreditorsRequirements = ({ detail: arr }) => {
		if (!(isBlank($form.creditors_requirement_ids) && isBlank(arr))) {
			$form.creditors_requirement_ids = [...arr];
		}
	};

	const handleEditRunningCostItem = id => dispatch('editRunningCost', id);

	$: kind1 = $form && toArray(data.kinds).find(k => String(k.value) === String($form.kind_id1));
	$: formFieldsKind1 = kind1 && kind1.form_fields;
	$: kind2 = $form && toArray(data.kinds).find(k => String(k.value) === String($form.kind_id2));
	$: formFieldsKind2 = kind2 && kind2.form_fields;

	$: if ($form?.extended_data && !$form.extended_data['contractTerminated']) { $form.extended_data['infoOnTerminationOfContract'] = null }

	$: if (
		$form &&
		(['transport', 'land', 'residential', 'non_residential'].includes(kindsCodes[String($form.kind_id0)]) || kindsCodes[String($form.kind_id1)] === 'weapon')
	) {
		if (isBlank($form.extended_data['ownershipType'])) {
			$form.extended_data['ownershipType'] = 'individual';
		}
		if ($form.extended_data['ownershipType'] !== 'fractional') {
			$form.extended_data['debtorShare'] = null;
		}
		if ($form.extended_data['ownershipType'] === 'individual') {
			$form.extended_data['otherOwners'] = null;
		}
	}

	$: if ($form && ['transport', 'land', 'residential', 'non_residential', 'other', 'immovables'].includes(kindsCodes[String($form.kind_id0)])) {
		if (isBlank($form.extended_data['location'])) {
			$form.extended_data['location'] = '';
		}
	}

	$: if ($form && kindsCodes[String($form.kind_id0)] === 'share') {
		if (isBlank($form.extended_data['organizationNameAndLegalForm'])) {
			$form.extended_data['organizationNameAndLegalForm'] = '';
		}
		if (isBlank($form.extended_data['location'])) {
			$form.extended_data['location'] = '';
		}
	}

	$: if (isPresent($form) && JSON.stringify($form.creditors_requirement_ids) !== JSON.stringify(currentCreditorsRequirementIds)) {
		currentCreditorsRequirementIds = [...$form.creditors_requirement_ids];
	}

	$: if (isPresent($form) && $form.kind_id && isBlank(Object.keys($form).filter(k => /^kind_id\d+$/.test(k))) && (!initBlank || $form.id)) {
		if ($form.kind_id !== $form.path_ids.slice(-1) && !$form.id) {
			$form.path_ids = [$form.kind_id];
		}
		for (let i in $form.path_ids) {
			formControl.setItem({ [`kind_id${i}`]: $form.path_ids[i] });
		}
	}

	$: if (isPresent($form) && isBlank($form.excluded_from_bankruptcy)) {
		$form.exclusion_grounds = '';
	}

	$: if (isPresent($form) && isPresent($form.extended_data['onlyResidence'])) {
		$form.excluded_from_bankruptcy = true;
		if (isBlank($form.exclusion_grounds)) {
			$form.exclusion_grounds = 'Является единственным жильём';
		} else if (!/Является единственным жильём/.test($form.exclusion_grounds)) {
			$form.exclusion_grounds = [$form.exclusion_grounds, 'Является единственным жильём'].join('; ');
		}
	} else if (isPresent($form) && isBlank($form.extended_data['onlyResidence'])) {
		const exclusionGrounds = String($form.exclusion_grounds)
			.split('; ')
			.filter(s => s != 'Является единственным жильём');
		if (isBlank(exclusionGrounds)) {
			$form.excluded_from_bankruptcy = false;
			$form.exclusion_grounds = '';
		} else {
			$form.exclusion_grounds = exclusionGrounds.join('; ');
		}
	}

	$: if (mounted && organizationInn) {
		IMask(organizationInn, { mask: '0000000000' });
	}

	$: if (isPresent($form) && $form.extended_data['authorizedCapital'] && $form.extended_data['participationShare']) {
		$form.extended_data['nominalShareSize'] = Math.round($form.extended_data['authorizedCapital'] * $form.extended_data['participationShare']) / 100;
	} else if (isPresent($form)) {
		//$form.extended_data['nominalShareSize'] = null;
	}

	$: filledRequiredFields = $form && filledKinds && isPresent($form.name)

	let creating = false
	$: if (isBlank($form?.id) && filledRequiredFields) {
		if (!creating) { secretlyСreate() }
		creating = true
	}

	const secretlyСreate = async () => {
		const response = await submitForm()
		$form = response?.item
		creating = false
	}

	$: if (isPresent($form) && !isNewInstance) { isNewInstance = isBlank($form?.id) }

	$: if (isPresent($form) && isBlank($form.excluded_from_bankruptcy)) { $form.exclusion_grounds = '' }

	$: if (isPresent($form) && isPresent($form.extended_data['onlyResidence'])) {
		$form.excluded_from_bankruptcy = true
		if (isBlank($form.exclusion_grounds)) {
			$form.exclusion_grounds = 'Является единственным жильём'
		}	else if (!/Является единственным жильём/.test($form.exclusion_grounds)) {
			$form.exclusion_grounds = [$form.exclusion_grounds, 'Является единственным жильём'].join('; ')
		}
		for (let i in $form.path_ids) {
			formControl.setItem({ [`kind_id${i}`]: $form.path_ids[i] });
		}
	}

	$: if (isPresent($form) && isBlank($form.excluded_from_bankruptcy)) {
		$form.exclusion_grounds = '';
	}

	$: if (isPresent($form) && isPresent($form.extended_data['onlyResidence'])) {
		$form.excluded_from_bankruptcy = true;
		if (isBlank($form.exclusion_grounds)) {
			$form.exclusion_grounds = 'Является единственным жильём';
		} else if (!/Является единственным жильём/.test($form.exclusion_grounds)) {
			$form.exclusion_grounds = [$form.exclusion_grounds, 'Является единственным жильём'].join('; ');
		}
	} else if (isPresent($form) && isBlank($form.extended_data['onlyResidence'])) {
		const exclusionGrounds = String($form.exclusion_grounds)
			.split('; ')
			.filter(s => s != 'Является единственным жильём');
		if (isBlank(exclusionGrounds)) {
			$form.excluded_from_bankruptcy = false;
			$form.exclusion_grounds = '';
		} else {
			$form.exclusion_grounds = exclusionGrounds.join('; ');
		}
	}

	$: if (mounted && organizationInn) {
		IMask(organizationInn, { mask: '0000000000' });
	}

	$: if (isPresent($form) && $form.extended_data['authorizedCapital'] && $form.extended_data['participationShare']) {
		$form.extended_data['nominalShareSize'] = Math.round($form.extended_data['authorizedCapital'] * $form.extended_data['participationShare']) / 100;
	} else if (isPresent($form)) {
		$form.extended_data['nominalShareSize'] = null;
	}

	onMount(() => {
		data = { ...data, kinds: $options.possessionKind };
		mounted = true;
	});
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	formOptions={{ ...formOptions, model: 'possession', controller: 'possessions' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<ChainFields {disabled} kinds={data.kinds} {formControl} bind:filled={filledKinds}/>
	<!-- Наименование -->
	<Field name="name" label="Наименование" required={true}>
		<input
			{disabled}
			name="name"
			class="form-control"
			bind:value={$form.name}
			on:change={handleChange}
			placeholder={['money', 'debit'].includes(kindsCodes[String($form.kind_id0)])
				? namePlaceholders[kindsCodes[String($form.kind_id0)]]
				: 'Введите наименование'}
		/>
	</Field>
	{#if toArray(formFieldsKind1).includes('manufacturer') || toArray(formFieldsKind2).includes('manufacturer')}
		<!-- Производитель -->
		<Field name={['extended_data', 'manufacturer']} label="Производитель">
			<input
				{disabled}
				name="extended_data[manufacturer]"
				class="form-control"
				bind:value={$form.extended_data['manufacturer']}
				on:change={handleChange}
				placeholder="Введите производителя"
			/>
		</Field>
	{/if}
	{#if ['transport', 'movables'].includes(kindsCodes[String($form.kind_id0)])}
		<!-- Марка (тип) и модель -->
		<Field name={['extended_data', 'model']} label="Марка (тип) и модель">
			<input
				{disabled}
				name="extended_data[model]"
				class="form-control"
				bind:value={$form.extended_data['model']}
				on:change={handleChange}
				placeholder="Введите марку (тип) и модель"
			/>
		</Field>
	{/if}
	{#if toArray(formFieldsKind1).includes('vesselName') || toArray(formFieldsKind2).includes('vesselName')}
		<!-- Название судна -->
		<Field name={['extended_data', 'vesselName']} label="Название судна">
			<input
				{disabled}
				name="extended_data[vesselName]"
				class="form-control"
				bind:value={$form.extended_data['vesselName']}
				on:change={handleChange}
				placeholder="“Судно”"
			/>
		</Field>
	{/if}
	{#if ['transport', 'movables'].includes(kindsCodes[String($form.kind_id0)])}
		<!-- Государственный (регистрационный, бортовой) номер -->
		<Field name={['extended_data', 'stateRegNumber']} label="Государственный (регистрационный, бортовой) номер">
			<input
				{disabled}
				name="extended_data[stateRegNumber]"
				class="form-control"
				bind:value={$form.extended_data['stateRegNumber']}
				on:change={handleChange}
				placeholder="Введите номер"
			/>
		</Field>
		<!-- Год выпуска (постройки) -->
		<Field name={['extended_data', 'manufactureYear']} label="Год выпуска (постройки)">
			<input
				{disabled}
				type="number"
				name="extended_data[manufactureYear]"
				class="form-control"
				bind:value={$form.extended_data['manufactureYear']}
				on:change={handleChange}
				placeholder={'2000'}
			/>
		</Field>
		<!-- Идентификационный (заводской, строительный идентификационный, серийный) номер -->
		<Field name={['extended_data', 'idNumber']} label="Идентификационный (заводской, строительный идентификационный, серийный) номер">
			<input
				{disabled}
				name="extended_data[idNumber]"
				class="form-control"
				bind:value={$form.extended_data['idNumber']}
				on:change={handleChange}
				placeholder="Введите номер"
			/>
		</Field>
	{/if}
	{#if ['land', 'residential', 'non_residential', 'immovables'].includes(kindsCodes[String($form.kind_id0)])}
		<!-- Вид объекта недвижимости -->
		<Field name={['extended_data', 'propertyType']} label="Вид объекта недвижимости">
			<input
				{disabled}
				name="extended_data[propertyType]"
				class="form-control"
				bind:value={$form.extended_data['propertyType']}
				on:change={handleChange}
				placeholder="Введите вид объекта"
			/>
		</Field>
		<!-- Назначение объекта недвижимости -->
		<Field name={['extended_data', 'propertyPurpose']} label="Назначение объекта недвижимости">
			<input
				{disabled}
				name="extended_data[propertyPurpose]"
				class="form-control"
				bind:value={$form.extended_data['propertyPurpose']}
				on:change={handleChange}
				placeholder="Введите назначение объекта"
			/>
		</Field>
		<!-- Кадастровый номер -->
		<Field name={['extended_data', 'cadastralNumber']} label="Кадастровый номер">
			<input
				{disabled}
				name="extended_data[cadastralNumber]"
				class="form-control"
				bind:value={$form.extended_data['cadastralNumber']}
				on:change={handleChange}
				placeholder="Введите номер"
			/>
		</Field>
		<!-- Виды разрешенного использования -->
		<Field name={['extended_data', 'permittedUses']} label="Виды разрешенного использования">
			<input
				{disabled}
				name="extended_data[permittedUses]"
				class="form-control"
				bind:value={$form.extended_data['permittedUses']}
				on:change={handleChange}
				placeholder="Введите виды"
			/>
		</Field>
	{/if}
	{#if ['transport', 'land', 'residential', 'non_residential', 'other', 'immovables'].includes(kindsCodes[String($form.kind_id0)])}
		<!-- Место нахождения -->
		<Field name={['extended_data', 'location']} label="Место нахождения">
			<SearchBy
				{disabled}
				method="address"
				id={generateId('possession_location')}
				name="extended_data[location]"
				bind:value={$form.extended_data['location']}
				placeholder="100000, Московская область, г.Королев, ул.Первая, д.1"
				autocomplete="off"
			/>
		</Field>
	{/if}
	{#if ['land', 'residential', 'non_residential', 'immovables'].includes(kindsCodes[String($form.kind_id0)])}
		<!-- Площадь -->
		<Field name={['extended_data', 'area']} label="Площадь">
			<input
				{disabled}
				name="extended_data[area]"
				class="form-control"
				bind:value={$form.extended_data['area']}
				on:change={handleChange}
				placeholder="100 кв. м"
			/>
		</Field>
	{/if}
	{#if ['transport', 'land', 'residential', 'non_residential', 'immovables'].includes(kindsCodes[String($form.kind_id0)]) || kindsCodes[String($form.kind_id1)] === 'weapon'}
		<!-- Вид собственности -->
		<Field name={['extended_data', 'ownershipType']} label="Вид собственности">
			{#each toArray(data.ownership_types) as option}
				<label class="d-flex">
					<input
						{disabled}
						type="radio"
						bind:group={$form.extended_data['ownershipType']}
						class="align-self-center m-r-xs"
						value={option.value}
					/>
					{option.label}
				</label>
			{/each}
		</Field>
	{/if}
	{#if ['transport', 'land', 'residential', 'non_residential'].includes(kindsCodes[String($form.kind_id0)])}
		{#if $form.extended_data['ownershipType'] === 'fractional'}
			<!-- Доля должника -->
			<Field name={['extended_data', 'debtorShare']} label="Доля должника">
				<input
					{disabled}
					name="extended_data[debtorShare]"
					class="form-control"
					bind:value={$form.extended_data['debtorShare']}
					on:change={handleChange}
					placeholder={'50%'}
				/>
			</Field>
		{/if}
		{#if ['fractional', 'common'].includes($form.extended_data['ownershipType'])}
			<!-- Иные собственники -->
			<Field name={['extended_data', 'otherOwners']} label="Иные собственники">
				<input
					{disabled}
					name="extended_data[otherOwners]"
					class="form-control"
					bind:value={$form.extended_data['otherOwners']}
					on:change={handleChange}
					placeholder="Введите иных собственников"
				/>
			</Field>
		{/if}
	{/if}
	{#if ['land', 'residential', 'non_residential', 'immovables'].includes(kindsCodes[String($form.kind_id0)])}
		<!-- Основание приобретения -->
		<Field name={['extended_data', 'acquisitionBasis']} label="Основание приобретения">
			<input
				{disabled}
				name="extended_data[acquisitionBasis]"
				class="form-control"
				bind:value={$form.extended_data['acquisitionBasis']}
				on:change={handleChange}
				placeholder="Введите основание"
			/>
		</Field>
	{/if}
	{#if kindsCodes[String($form.kind_id0)] === 'share'}
		<!-- Наименование и организационно-правовая форма организации -->
		<Field name={['extended_data', 'organizationNameAndLegalForm']} label="Наименование и организационно-правовая форма организации">
			<SearchBy
				{disabled}
				method="party"
				id={generateId('organization_name')}
				name="extended_data[organizationNameAndLegalForm]"
				bind:value={$form.extended_data['organizationNameAndLegalForm']}
				placeholder="Введите наименование, ИНН, ОГРН или адрес"
				autocomplete="off"
			/>
		</Field>
		<!-- ИНН организации -->
		<Field name={['extended_data', 'organizationINN']} label="ИНН организации">
			<input
				{disabled}
				name="extended_data[organizationINN]"
				class="form-control"
				bind:this={organizationInn}
				bind:value={$form.extended_data['organizationINN']}
				on:change={handleChange}
				placeholder="1234567890"
			/>
		</Field>
		<!-- ИНН организации -->
		<Field name={['extended_data', 'organizationINN']} label="ИНН организации">
			<input
				{disabled}
				name="extended_data[organizationINN]"
				class="form-control"
				bind:this={organizationInn}
				bind:value={$form.extended_data['organizationINN']}
				on:change={handleChange}
				placeholder="1234567890"
			/>
		</Field>
		<!-- Местонахождение организации -->
		<Field name={['extended_data', 'location']} label="Местонахождение организации">
			<SearchBy
				{disabled}
				method="address"
				id={generateId('organization_location')}
				name="extended_data[location]"
				bind:value={$form.extended_data['location']}
				placeholder="100000, Московская область, г.Королев, ул.Первая, д.1"
				autocomplete="off"
			/>
		</Field>
		<!-- Уставный, складочный капитал, паевый фонд (руб.) -->
		<Field name={['extended_data', 'authorizedCapital']} label="Уставный, складочный капитал, паевый фонд (руб.)" short={true}>
			<InputMoney
				{disabled}
				name="extended_data[authorizedCapital]"
				placeholder="Введите капитал или фонд"
				bind:value={$form.extended_data['authorizedCapital']}
				klass="form-control text-right"
			/>
		</Field>
		{#if kindsCodes[String($form.kind_id1)] === 'stock'}
			<!-- Количество акций -->
			<Field name={['extended_data', 'sharesNumber']} label="Количество акций" short={true}>
				<input
					{disabled}
					type="number"
					name="extended_data[sharesNumber]"
					class="form-control"
					bind:value={$form.extended_data['sharesNumber']}
					on:change={handleChange}
					placeholder={'10'}
				/>
			</Field>
		{/if}
		{#if ['shares_in_commercial_organizations', 'stock'].includes(kindsCodes[String($form.kind_id1)]) || $bankruptIsOrganization}
			<!-- Доля участия -->
			<Field name={['extended_data', 'participationShare']} label="Доля участия" short={true}>
				<input
					{disabled}
					type="number"
					name="extended_data[participationShare]"
					class="form-control"
					bind:value={$form.extended_data['participationShare']}
					on:change={handleChange}
					placeholder={'10%'}
				/>
			</Field>
		{/if}
		{#if ['shares_in_commercial_organizations', 'stock'].includes(kindsCodes[String($form.kind_id1)])}
			<!-- Номинальный размер доли -->
			<Field name={['extended_data', 'nominalShareSize']} label="Номинальный размер доли" short={true}>
				<InputMoney {disabled} name="extended_data[nominalShareSize]" bind:value={$form.extended_data['nominalShareSize']} klass="form-control text-right" />
			</Field>
		{/if}
		{#if kindsCodes[String($form.kind_id1)] === 'stock'}
			<!-- Номинальный размер доли -->
			<Field name={['extended_data', 'nominalShareSize']} label="Номинальный размер доли" short={true}>
				<InputMoney
					{disabled}
					name="extended_data[nominalShareSize]"
					placeholder={'10'}
					bind:value={$form.extended_data['nominalShareSize']}
					klass="form-control text-right"
				/>
			</Field>
		{/if}
		<!-- Основание участия -->
		<Field name={['extended_data', 'participationBasis']} label="Основание участия">
			<input
				{disabled}
				name="extended_data[participationBasis]"
				class="form-control"
				bind:value={$form.extended_data['participationBasis']}
				on:change={handleChange}
				placeholder="Введите основание участия"
			/>
		</Field>
	{/if}
	{#if kindsCodes[String($form.kind_id0)] === 'other' && kindsCodes[String($form.kind_id1)] === 'weapon'}
		<!-- Вид оружия -->
		<Field name={['extended_data', 'weaponType']} label="Вид оружия">
			<input
				{disabled}
				name="extended_data[weaponType]"
				class="form-control"
				bind:value={$form.extended_data['weaponType']}
				on:change={handleChange}
				placeholder="Введите вид оружия"
			/>
		</Field>
		<!-- Марка оружия -->
		<Field name={['extended_data', 'weaponModel']} label="Марка оружия">
			<input
				{disabled}
				name="extended_data[weaponModel]"
				class="form-control"
				bind:value={$form.extended_data['weaponModel']}
				on:change={handleChange}
				placeholder="Введите марку оружия"
			/>
		</Field>
		<!-- Номер оружия -->
		<Field name={['extended_data', 'weaponNumber']} label="Номер оружия">
			<input
				{disabled}
				name="extended_data[weaponNumber]"
				class="form-control"
				bind:value={$form.extended_data['weaponNumber']}
				on:change={handleChange}
				placeholder="Введите номер оружия"
			/>
		</Field>
		<!-- Номер разрешения РОХа -->
		<Field name={['extended_data', 'rocPermitNumber']} label="Номер разрешения РОХа">
			<input
				{disabled}
				name="extended_data[rocPermitNumber]"
				class="form-control"
				bind:value={$form.extended_data['rocPermitNumber']}
				on:change={handleChange}
				placeholder="Введите номер разрешения"
			/>
		</Field>
		<!-- Дата выдачи разрешения РОХа -->
		<Datepicker
			model='possession'
			attribute='extended_data-rocPermitDate'
			label='Дата выдачи разрешения РОХа'
			placeholder='дд.мм.гггг'
			maskOptions={{ mask: Date }}
			{disabled}
			onchange={handleChange}
			bind:value={$form.extended_data['rocPermitDate']}
		/>
		<!-- Кем выдано разрешение РОХа -->
		<Field name={['extended_data', 'rocPermitDepartment']} label="Кем выдано разрешение РОХа">
			<input
				{disabled}
				name="extended_data[rocPermitDepartment]"
				class="form-control"
				bind:value={$form.extended_data['rocPermitDepartment']}
				on:change={handleChange}
				placeholder="Введите кем выдано разрешение"
			/>
		</Field>
		<!-- Срок действия разрешения РОХа -->
		<Datepicker
			model='possession'
			attribute='extended_data-rocPermitExpiries'
			label='Срок действия разрешения РОХа'
			placeholder='дд.мм.гггг'
			maskOptions={{ mask: Date }}
			{disabled}
			onchange={handleChange}
			bind:value={$form.extended_data['rocPermitExpiries']}
		/>
	{/if}
	{#if kindsCodes[String($form.kind_id0)] === 'other' && kindsCodes[String($form.kind_id1)] === 'securities'}
		<!-- Вид ценной бумаги -->
		<Field name={['extended_data', 'securityType']} label="Вид ценной бумаги">
			<input
				{disabled}
				name="extended_data[securityType]"
				class="form-control"
				bind:value={$form.extended_data['securityType']}
				on:change={handleChange}
				placeholder="Введите вид ценной бумаги"
			/>
		</Field>
		<!-- Лицо, выпустившее ценную бумагу -->
		<Field name={['extended_data', 'personIssuedSecurity']} label="Лицо, выпустившее ценную бумагу">
			<input
				{disabled}
				name="extended_data[personIssuedSecurity]"
				class="form-control"
				bind:value={$form.extended_data['personIssuedSecurity']}
				on:change={handleChange}
				placeholder="Укажите лицо, выпустившее ценную бумагу"
			/>
		</Field>
		<!-- Номинальная величина обязательства (руб.) -->
		<Field name={['extended_data', 'nominalLiability']} label="Номинальная величина обязательства (руб.)" short={true}>
			<InputMoney
				{disabled}
				name="extended_data[nominalLiability]"
				placeholder="100,00"
				bind:value={$form.extended_data['nominalLiability']}
				klass="form-control text-right"
			/>
		</Field>
		<!-- Общее количество ценных бумаг -->
		<Field name={['extended_data', 'total']} label="Общее количество ценных бумаг">
			<input
				{disabled}
				name="extended_data[total]"
				class="form-control"
				bind:value={$form.extended_data['total']}
				on:change={handleChange}
				placeholder={'10'}
			/>
		</Field>
	{/if}
	<!-- Стоимость (руб.) / Балансовая стоимость (руб.) -->
	<Field name="cost" label={$bankruptIsPerson ? 'Стоимость (руб.)' : 'Балансовая стоимость (руб.)'} short={true}>
		<InputMoney {disabled} name="cost" bind:value={$form.cost} klass="form-control text-right" />
	</Field>
	<!-- Рыночная стоимость (руб.) -->
	{#if $bankruptIsOrganization}
		<Field name="market_cost" label="Рыночная стоимость (руб.)" short={true}>
			<InputMoney {disabled} name="market_cost" bind:value={$form.market_cost} klass="form-control text-right" />
		</Field>
	{/if}
	<!-- Кто определил стоимость -->
	{#if $bankruptIsPerson}
		<Field name="valuer" label="Кто определил стоимость" required={true}>
			{#each toArray(data.valuers) as option}
				<label class="d-flex">
					<input {disabled} type="radio" bind:group={$form.valuer} class="align-self-center m-r-xs" value={option.value} />
					{option.label}
				</label>
			{/each}
		</Field>
	{/if}
	{#if kindsCodes[String($form.kind_id0)] === 'residential'}
		<!-- Является единственным жильём -->
		<Field
			name={['extended_data', 'onlyResidence']}
			label="Является единственным жильём"
			fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left"
		>
			<input
				{disabled}
				id="checkbox_only_residence"
				name="extended_data[onlyResidence]"
				type="checkbox"
				class="align-self-center m-r-sm m-l-xs"
				bind:checked={$form.extended_data['onlyResidence']}
				on:change={handleChange}
			/>
			<label class="label-text" for="checkbox_only_residence" style="margin-bottom: 0;">да, является</label>
		</Field>
	{/if}
	<!-- Исключен из конкурсной массы -->
	<Field name="excluded_from_bankruptcy" label="Исключен из конкурсной массы" fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left">
		<input
			disabled={disabled || $form.extended_data['onlyResidence']}
			id="checkbox_excluded_from_bankruptcy"
			name="excluded_from_bankruptcy"
			type="checkbox"
			class="align-self-center m-r-sm m-l-xs"
			bind:checked={$form.excluded_from_bankruptcy}
			on:change={handleChange}
		/>
		<label class="label-text" for="checkbox_excluded_from_bankruptcy" style="margin-bottom: 0;">да, исключен</label>
	</Field>
	{#if $form.excluded_from_bankruptcy}
		<!-- Основание исключения -->
		<Field name="exclusion_grounds" label="Основание исключения">
			<input
				{disabled}
				name="exclusion_grounds"
				class="form-control"
				bind:value={$form.exclusion_grounds}
				on:change={handleChange}
				placeholder="Введите основание исключения"
			/>
		</Field>
	{/if}
	<!-- Является предметом залога -->
	<Field name="is_pledge_subject" label="Является предметом залога" fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left">
		<input
			{disabled}
			id="checkbox_is_pledge_subject"
			name="is_pledge_subject"
			type="checkbox"
			class="align-self-center m-r-sm m-l-xs"
			bind:checked={$form.is_pledge_subject}
			on:change={handleChange}
		/>
		<label class="label-text" for="checkbox_is_pledge_subject" style="margin-bottom: 0;">да, является</label>
	</Field>
	<!-- Залоговый кредитор -->
	{#if isPresent($form.is_pledge_subject)}
		<!-- Залоговые требования -->
		<Field name="creditors_requirement_ids" label="Залоговые требования">
			<Select
				isDisabled={disabled}
				name="creditors_requirement_ids"
				items={data.creditors_requirements}
				value={currentCreditorsRequirementIds}
				on:select={assignCreditorsRequirements}
				isMulti={true}
				containerClasses="form-control"
				placeholder="Выбрать требование"
			></Select>
		</Field>
		<!-- Расходы по продаже залогового имущества -->
		<div class="form-group row">
			<label for class="col-form-label col-sm-4 col-lg-3 col-xl-4">Расходы по продаже залогового имущества</label>
			<div class="col-sm-8 col-lg-9 col-xl-8">
				<div class="badge-container">
					{#each toArray(data.running_costs) as row}
						<div role="button" tabindex="0" on:click|stopPropagation={() => handleEditRunningCostItem(row.id)} on:keypress|stopPropagation>
							{formatSum(row.sum) + ' руб. (' + truncateString(row.expense_type_text, 18) + ', ' + formatDate(row.date_at) + ')'}
						</div>
					{/each}
				</div>
			</div>
		</div>
	{/if}
	<div class="form-group row">
		<label for class='col-form-label col-sm-4 col-lg-3 col-xl-4'><strong>До введения банкротства:</strong></label>
	</div>
	{#if (['transport', 'land', 'residential', 'non_residential', 'share', 'other'].includes(kindsCodes[String($form.kind_id0)]) && $bankruptIsPerson) ||
			 (['movables', 'immovables', 'current_funds', 'non_current_funds', 'share'].includes(kindsCodes[String($form.kind_id0)]) && $bankruptIsOrganization)}
		<!-- Дата покупки (постановки на учёт) -->
		<Datepicker
			model='possession'
			attribute='extended_data-buyDate'
			label='Дата покупки (постановки на учёт)'
			placeholder='дд.мм.гггг'
			maskOptions={{ mask: Date }}
			{disabled}
			onchange={handleChange}
			bind:value={$form.extended_data['buyDate']}
		/>
		<!-- Дата продажи (снятия с учёта) -->
		<Datepicker
			model='possession'
			attribute='extended_data-saleDate'
			label='Дата продажи (снятия с учёта)'
			placeholder='дд.мм.гггг'
			maskOptions={{ mask: Date }}
			{disabled}
			onchange={handleChange}
			bind:value={$form.extended_data['saleDate']}
		/>
		<!-- Цена продажи (руб.) -->
		<Field name={['extended_data', 'saleCost']} label="Цена продажи (руб.)" short={true}>
			<InputMoney {disabled} name="extended_data[saleCost]" bind:value={$form.extended_data['saleCost']} klass="form-control text-right" />
		</Field>
	{/if}
	<div class="form-group row">
		<label for class='col-form-label col-sm-4 col-lg-3 col-xl-4'><strong>После введения банкротства:</strong></label>
	</div>
	<!-- Способ реализации -->
	<Field {disabled} name={['extended_data', 'saleMethod']} label="Способ реализации">
		<Select
			name="extended_data[saleMethod]"
			items={data.sale_methods || []}
			value={$form.extended_data['saleMethod']}
			onchange={handleChange}
			isDisabled={disabled}
			containerClasses="form-control"
			placeholder="Выбрать способ реализации"
		/>
	</Field>
	<!-- Дата договора -->
	<Datepicker
		model='possession'
		attribute='extended_data-contractDate'
		label='Дата договора'
		placeholder='дд.мм.гггг'
		maskOptions={{ mask: Date }}
		{disabled}
		onchange={handleChange}
		bind:value={$form.extended_data['contractDate']}
	/>
	<!-- Номер договора -->
	<Field name={['extended_data', 'contractNumber']} label="Номер договора">
		<input
			{disabled}
			name="extended_data[contractNumber]"
			class="form-control"
			bind:value={$form.extended_data['contractNumber']}
			on:change={handleChange}
			placeholder="Введите номер договора"
		/>
	</Field>
	<!-- Стоимость по договору (руб.) -->
	<Field name={['extended_data', 'costUnderContract']} label="Стоимость по договору (руб.)" short={true}>
		<InputMoney {disabled} name="extended_data[costUnderContract]" bind:value={$form.extended_data['costUnderContract']} klass="form-control text-right" />
	</Field>
	<!-- Договор расторгнут -->
	<Field name={['extended_data', 'contractTerminated']} label="Договор расторгнут" fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left">
		<input
			disabled={disabled}
			id="checkbox-extended_data-contract_terminated"
			name="extended_data[contractTerminated]"
			type="checkbox"
			class="align-self-center m-r-sm m-l-xs"
			bind:checked={$form.extended_data['contractTerminated']}
			on:change={handleChange}
		/>
		<label class="label-text" for="checkbox-extended_data-contract_terminated" style="margin-bottom: 0;">да, расторгнут</label>
	</Field>
	{#if !$form.extended_data['contractTerminated']}
		<!-- Оплачено (руб.) -->
		<Field name="receipts" label="Оплачено (руб.)">
			<div class="badge-container">
				{#each toArray($form.receipts) as row}
					<div {disabled} role="button" tabindex="0" on:click|stopPropagation={!disabled && (() => {})} on:keypress|stopPropagation>
						{formatSum(row.sum) + ' руб. (' + formatDate(row.date_at) + ')'}
					</div>
				{/each}
			</div>
		</Field>
	{:else}
		<!-- Сведения о расторжении договора -->
		<Field name={['extended_data', 'infoOnTerminationOfContract']} label="Сведения о расторжении договора">
			<textarea
				{disabled}
				name="extended_data[infoOnTerminationOfContract]"
				rows="3"
				class="form-control"
				bind:value={$form.extended_data['infoOnTerminationOfContract']}
				on:change={handleChange}
			/>
		</Field>
		{/if}
	<ScansSet label='Документы', scansSet={$form.scans_set} disabled={!filledRequiredFields} on:changed={({detail: scansSet}) => $form.scans_set = scansSet}/>
	<ErrorMessage message={ $errorsBase } on:click={() => $errorsBase = null}/>
</Form>
