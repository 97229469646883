/** @format */

import { get } from 'svelte/store';
import { currencyFormat } from 'utils/formats';
import { transmitter as _transmitter, options as _options, bankruptIsPerson as _bankruptIsPerson } from '~/js/global_stores/base';
import {
	pdfDctData as _pdfDctData,
	currentData as _currentData,
	requiredFieldsFilled as _requiredFieldsFilled,
	creditorsRequirements as _creditorsRequirements,
	allPossessions as _allPossessions,
	allOrganizationPossessions as _allOrganizationPossessions,
	actualMeasures as _actualMeasures,
} from './stores/stores';
import { triggers as _triggers, derivedData as _derivedData } from './stores/analytics';
import { encodeTemplate } from './conformities';
import {
	reworkingStyles,
	reworkingMeetingStyles,
	clearFields,
	transformOtherFields,
	transformBankruptCategoryFields,
	transformAmountFields,
	transformPercentFields,
	transformRegistrarFields,
	transformSurnameFields,
	transformFormProvidingInformation,
	transformObtainingMethodFields,
	transformBankAccountsBlock,
	transformChildrenBlock,
	transformSpecialistsBlock,
	transformSpousesBlock,
	transformIncomeSourcesTable,
	transformEmployeesBlock,
	transformComplaintsBlock,
	transformCreditorMeetingsBlock,
	transformWorkerMeetingsBlock,
	transformMeasuresBlock,
	transformMeasuresTextBlock,
	transformSentNotificationsRequestsBlock,
	transformAnswersBlock,
	transformAnswersFnsPfrFssBlock,
	transformAnswersAboutPropertyList,
	transformReceivablesBlock,
	transformPersonAssetsTable,
	transformPersonLiabilitiesTable,
	transformPersonResponsibilitiesTable,
	transformPersonPossessionsTable,
	transformArbitrManagerAdjectiveFields,
	transformOptionalParagraph,
	insertBankAccounts,
	transformTriggers,
	transformRunningCostsBlock,
	transformReceiptsBlock,
	transformPayoutsBlock,
	transformReceiptsPayoutBlock,
	transformUniqueFields,
	transformTable,
	transformArbitraryText,
	transformAdditionalArbitraryText,
	transformSignature,
	transformMeetingClauses,
	transformMeetingQuestions,
	transformMeetingSolutions,
	transformMeetingFields,
	transformAppendices,
	transformBankruptInfo,
} from './transformations';
import Case from 'case';
import moment from 'moment';
import { isPresent, isBlank, toArray, formatSum, formatDate, isNumber, toFloat } from '~/js/utils/tools';

export const getTemplate = ({ applyStyles }, transmitter = get(_transmitter)) => {
	//const transmitter = get(_transmitter)
	const dct = transmitter.dct;

	if (isBlank(dct)) {
		return { preview: '', pdfData: '' };
	}

	const options = get(_options);
	const currentData = get(_currentData);
	const requiredFieldsFilled = get(_requiredFieldsFilled);
	const creditorsRequirements = get(_creditorsRequirements);
	const allPossessions = get(_allPossessions);
	const allOrganizationPossessions = get(_allOrganizationPossessions);
	const actualMeasures = get(_actualMeasures);
	const triggers = get(_triggers);
	const derivedData = get(_derivedData);
	const bankruptIsPerson = get(_bankruptIsPerson);

	const substitutions = dct.substitutions;
	const baseTemplate = dct.category == 'financial_analysis' ? dct.template : encodeTemplate(dct.template, applyStyles);
	const splitedTemplate = baseTemplate && baseTemplate.split('%%ADDITIONAL_TEMPLATE_FRAGMENTS%%');
	const template = splitedTemplate && splitedTemplate[0];
	const additionalTemplateFragments = splitedTemplate && splitedTemplate[1];
	const isDraft = dct.status == 'draft';
	const measureKinds = options && options.measureKind;
	const bankruptCategories = options && options.bankruptCategory;
	const undeclaredDebts = transmitter && transmitter.undeclaredDebts;

	const specificExpenseTypes = bankruptIsPerson
		? [
				'person4',
				'person5',
				'person6',
				'person7',
				'person8',
				'person9',
				'person10',
				'person11',
				'person12',
				'person13',
				'person14',
				'person15',
				'person16',
				'person17',
				'person18',
				'person21',
			]
		: ['org13', 'org14', 'org15', 'org16', 'org17', 'org18', 'org19', 'org20', 'org21', 'org22', 'org23', 'org24', 'org26', 'org27', 'org28', 'org32'];

	const procedurePhase = transmitter.procedure.phase;
	const specificProcedureKind2 =
		procedurePhase == 'bankruptcy_proceedings'
			? 'org6' // Конкурсное производство
			: procedurePhase == 'supervision'
				? 'org1'
				: ''; // Наблюдение
	const specificProcedureKind =
		procedurePhase == 'debt_restructuring'
			? 'person1'
			: procedurePhase == 'property_realization'
				? 'person2'
				: procedurePhase == 'supervision'
					? 'org1'
					: procedurePhase == 'bankruptcy_proceedings'
						? 'org6'
						: '';
	const specificExpenseTypes2 = [
		'org13',
		'org14',
		'org15',
		'org16',
		'org17',
		'org18',
		'org19',
		'org20',
		'org21',
		'org22',
		'org23',
		'org24',
		'org26',
		'org27',
		'org28',
		'org32',
	];

	const tablesData = {
		incomes: { columns: ['index', 'year', 'sum'], sums: {} },
		specialists: {
			columns: [
				'kind_name',
				'accreditation',
				'name',
				'contract',
				'contract_date',
				'contract_completion_date',
				'payment',
				'payment_source_name',
				'payment_frequency_name',
				'court_decision_date',
			],
			// %%SPECIALISTS_CELL_...%% - в ячейке, %%SPECIALISTS_TOTAL_...%% - в суммирующей ячейке.
			sums: { total: { cell: [] } }, //{ total: { cell: ['payment'] } }
			resources:
				transmitter.specialists &&
				transmitter.specialists.map(s => {
					const contractInfo = [
						s['contract'],
						isPresent(s['contract_date']) ? `от ${formatDate(s['contract_date'], 'DD.MM.YYYY')} г.` : null,
						isPresent(s['contract_completion_date']) ? `действует до ${formatDate(s['contract_completion_date'], 'DD.MM.YYYY')} г.` : null,
					]
						.filter(i => i)
						.join(' ');
					const paymentInfo = [isNumber(toFloat(s['payment'])) ? `${currencyFormat(toFloat(s['payment']))} руб.` : null, s['payment_frequency_name']]
						.filter(i => i)
						.join(' ');
					s = {
						...s,
						contract: isPresent(contractInfo) ? contractInfo : '-',
						payment: isPresent(paymentInfo) ? paymentInfo : '-',
					};
					return s;
				}),
		},
		// meetings: { columns: ['date', 'agenda', 'solutions', 'publication_about', 'solution_publication'], sums: {} },
		measures: {
			columns: ['name', 'result'],
			sums: {},
			resourceKinds: measureKinds?.map((k, i) => ({ ...k, text: `${i + 1}. ${k.text}` })),
			resources:
				transmitter.measures &&
				Object.fromEntries(
					Object.entries(transmitter.measures)?.map(([k, v]) => [
						k,
						v.map(r => ({
							...r,
							result: [
								isPresent(r.result) ? `${r.result},` : null,
								isPresent(r.judicial_act) ? r.judicial_act : null,
								moment(r.judicial_act_date).isValid() ? ` от ${moment(r.judicial_act_date).format('DD.MM.YYYY')} г., ` : null,
								isPresent(r.court_name) ? r.court_name : null,
							]
								.filter(i => i)
								.join(' '),
						})),
					]),
				),
		},
		employees_working: {
			columns: ['index', 'full_name', 'position'],
			sums: {},
			resources: transmitter.employees && transmitter.employees.filter(e => !e.dismissal_date || e.dismissal_date > currentData.date),
		},
		employees_dismissed: {
			columns: ['index', 'full_name', 'position', 'dismissal_date'],
			sums: {},
			resources: transmitter.employees && transmitter.employees.filter(e => e.dismissal_date && e.dismissal_date <= currentData.date),
		},
		kdls: {
			columns: ['index', 'full_name', 'submission_claims_date', 'amount_of_claims', 'result'],
			sums: { total: { cell: ['amount_of_claims'] } },
			resources: transmitter.kdls,
		},
		bank_accounts: {
			columns: [
				'index',
				'counterparty_name',
				'bank_legal_address',
				'name_with_legal_address',
				'account_details',
				'receipts_sum_rub',
				'current_balance_rub',
				'balance_rub',
				'measures_taken',
				'result'
			],
			sums: {},
			resources: toArray(transmitter.bankAccounts).map(a => ({
				...a,
				bank_legal_address: a.bank_legal_address_full,
				name_with_legal_address: [a.counterparty_name, a.bank_legal_address_full].join('; '),
				account_details: `${a.kind_name} №${a.number}`,
				receipts_sum_rub: formatSum(toArray(transmitter.receipts).filter(r => +r.bank_account_id == +a.id).reduce((sum, r) => sum + parseFloat(r.sum), 0.0)),
			})),
		},
		complaints: {
			columns: ['name', 'date', 'content', 'article', 'decisionmaker', 'consideration_date', 'final_document', 'decision', 'decision_revision'],
			sums: {},
			resources:
				transmitter.complaints &&
				transmitter.complaints.map(c => {
					const contentInfo = [c['content'], c['article']].filter(i => i).join(' ');
					const finalDocumentInfo = [c['final_document'], c['consideration_date'] ? `от ${formatDate(c['consideration_date'], 'DD.MM.YYYY')} г.` : null]
						.filter(i => i)
						.join(' ');
					return {
						...c,
						content: isPresent(contentInfo) ? contentInfo : '-',
						final_document: isPresent(finalDocumentInfo) ? finalDocumentInfo : '-',
					};
				}),
		},
		running_costs: {
			columns: [
				'index',
				'counterparty_name',
				'procedure_kind_text',
				'expense_type_text',
				'expense_goal_name',
				'date',
				'sum',
				'maturity_date',
				'paid_sum',
				'outstanding_sum',
			],
			sums: {
				stages_total: {
					total_first_stage: { first_stage: ['sum', 'paid_sum', 'outstanding_sum'] },
					total_second_stage: { second_stage: ['sum', 'paid_sum', 'outstanding_sum'] },
					total_third_stage: { third_stage: ['sum', 'paid_sum', 'outstanding_sum'] },
					total_fourth_stage: { fourth_stage: ['sum', 'paid_sum', 'outstanding_sum'] },
					total_fifth_stage: { fifth_stage: ['sum', 'paid_sum', 'outstanding_sum'] },
				},
			},
			resources:
				transmitter.running_costs &&
				transmitter.running_costs.map(r => ({
					...r,
					expense_type_text: [r.expense_type_text, r.expense_reason].filter(i => isPresent(i)).join('.<br/>'),
				})),
		},
		monitoring_costs: {
			columns: ['index', 'expense_type_text', 'expense_goal_name', 'sum', 'protocol'],
			sums: { total: { cell: ['sum'] } },
			resources: toArray(transmitter.running_costs).filter(r => r.procedure_kind == specificProcedureKind2 && specificExpenseTypes2.includes(r.expense_type)),
		},
		debt_restructuring_costs: {
			columns: ['index', 'expense_type_text', 'expense_goal_name', 'sum', 'protocol'],
			sums: { total: { cell: ['sum'] } },
			resources: toArray(transmitter.running_costs).filter(r => r.procedure_kind == specificProcedureKind && specificExpenseTypes.includes(r.expense_type)),
		},
		organization_possessions: {
			columns: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
			sums: {},
			resources: allOrganizationPossessions,
		},
		possessions: {
			columns: [
				'name',
				'included_arbitr_manager',
				'included_appraiser',
				'pledgee',
				'excluded',
				'exclusion_grounds',
				'excluded_arbitr_manager',
				'excluded_appraiser',
			],
			sums: {
				generic_total: {
					generic_realty: {
						total_generic_subjects_land: {
							generic_subjects_land: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
						},
						total_generic_subjects_residential: {
							generic_subjects_residential: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
						},
						total_generic_subjects_non_residential: {
							generic_subjects_non_residential: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
						},
					},
					total_generic_subjects_transport: {
						generic_subjects_transport: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
					},
					total_generic_subjects_share: {
						generic_subjects_share: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
					},
					total_generic_subjects_other: {
						generic_subjects_other: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
					},
					money: {
						total_bank_accounts: {
							bank_accounts: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
						},
						total_receivables: {
							receivables: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
						},
					},
				},
				no_pledge_total: {
					no_pledge_realty: {
						total_no_pledge_subjects_land: {
							no_pledge_subjects_land: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
						},
						total_no_pledge_subjects_residential: {
							no_pledge_subjects_residential: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
						},
						total_no_pledge_subjects_non_residential: {
							no_pledge_subjects_non_residential: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
						},
					},
					total_no_pledge_subjects_transport: {
						no_pledge_subjects_transport: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
					},
					total_no_pledge_subjects_share: {
						no_pledge_subjects_share: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
					},
					total_no_pledge_subjects_other: {
						no_pledge_subjects_other: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
					},
					money: {
						total_bank_accounts: {
							bank_accounts: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
						},
						total_receivables: {
							receivables: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
						},
					},
				},
				pledge_total: {
					pledge_realty: {
						total_pledge_subjects_land: {
							pledge_subjects_land: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
						},
						total_pledge_subjects_residential: {
							pledge_subjects_residential: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
						},
						total_pledge_subjects_non_residential: {
							pledge_subjects_non_residential: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
						},
					},
					total_pledge_subjects_transport: {
						pledge_subjects_transport: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
					},
					total_pledge_subjects_share: {
						pledge_subjects_share: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
					},
					total_pledge_subjects_other: {
						pledge_subjects_other: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
					},
				},
			},
			resources: allPossessions,
		},
		creditors_requirements: {
			columns: ['index', 'counterparty_name', 'counterparty_name_inn', 'amount', 'paid_sum', 'paid_percent', 'paid_date'],
			sums: {
				requirements_total: {
					total_on_reestr: {
						total_first_stage: { first_stage: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
						total_second_stage: { second_stage: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
						total_third_stage: {
							third_stage_total_secured_by_pledge: { third_stage_secured_by_pledge: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
							third_stage_total_not_secured_by_pledge: { third_stage_not_secured_by_pledge: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
							third_stage_total_percents: { third_stage_percents: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
							third_stage_total_fines: { third_stage_fines: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
						},
					},
					total_off_reestr: { off_reestr: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
				},
			},
			resources: creditorsRequirements,
		},
		receipts: {
			columns: ['counterparty_name', 'receipt_date', 'receipt_sum'],
			sums: { total: { cell: ['receipt_sum'] } },
			resources: toArray(transmitter.receipts)
				.filter(a => a.account_is_main)
				.map(r => ({
					counterparty_name: `${r.counterparty_name}${isPresent(r.note) ? ` (${r.note})` : ''}`,
					receipt_date: r.date_at,
					receipt_sum: formatSum(r.sum),
				})),
		},
		receivables: {
			columns: ['index', 'counterparty_name', 'debt_amount', 'share_in_total_debt', 'paid_sum', 'note'],
			sums: { total: { cell: ['debt_amount', 'share_in_total_debt', 'paid_sum'] } },
			resources: toArray(transmitter.receivables),
		},
		receipts_payouts: {
			columns: ['bank_name', 'bank_location', 'account_kind', 'receipt_sum', 'receipt_date', 'payout_sum', 'payout_date', 'payout_doc'],
			sums: {},
			resources: [...toArray(transmitter.receipts).map(e => ({ type: 'receipt', ...e })), ...toArray(transmitter.payouts).map(e => ({ type: 'payout', ...e }))]
				.sort((a, b) => (a.date_at > b.date_at ? 1 : b.date_at > a.date_at ? -1 : 0))
				.map(r => ({
					bank_name: r.bank_name,
					bank_location: r.bank_location,
					account_kind: [r.account_number, r.account_kind].filter(e => isPresent(e)).join(' '),
					receipt_sum: r.type == 'receipt' ? formatSum(r.sum) : '',
					receipt_date: r.type == 'receipt' ? r.date_at : '',
					payout_sum: r.type == 'payout' ? formatSum(r.sum) : '',
					payout_date: r.type == 'payout' ? r.date_at : '',
					payout_doc: `платежный документ от ${formatDate(r.num_at)} №${r.num}, ${r.note}`,
				})),
		},
		meetings: {
			columns: ['date_at', 'agenda', 'solutions', 'publication_about', 'solution_publication'],
			sums: {},
			resources: ((transmitter && transmitter.meetings && transmitter.meetings.creditors) || []).sort((a, b) => a.id - b.id),
		},
		worker_meetings: {
			columns: ['date_at', 'agenda', 'solutions', 'publication_about', 'solution_publication'],
			sums: {},
			resources: (transmitter && transmitter.meetings && transmitter.meetings.employees) || [],
		},
	};

	const templates = transformTemplate({
		transmitter,
		substitutions,
		currentData,
		requiredFieldsFilled,
		creditorsRequirements,
		allPossessions,
		allOrganizationPossessions,
		actualMeasures,
		triggers,
		derivedData,
		applyStyles,
		template,
		additionalTemplateFragments,
		isDraft,
		measureKinds,
		bankruptCategories,
		undeclaredDebts,
		tablesData,
		dct,
	});

	_pdfDctData.update(() => templates.pdfData);

	return templates;
};

export const templateFieldSubstitution = template => {
	const transmitter = get(_transmitter);
	const dct = transmitter.dct;
	const substitutions = dct.substitutions;
	const isDraft = dct.status === 'draft';
	const derivedData = get(_derivedData);
	const requiredFieldsFilled = get(_requiredFieldsFilled);
	const currentData = get(_currentData);

	const replacements = {
		region_id: 'region',
		realty_location_id: 'realty_location',
		sro_id: 'sro_full_name',
		correspondent_id: 'correspondent_short_name',
		court_id: 'court_full_name',
		appendices: 'appendices_list',
	};

	const keys = [
		...new Set([...dct.form_fields.map(key => Case.snake(key)), ...Object.keys(substitutions), ...Object.keys(derivedData).map(key => Case.snake(key))]),
	];

	for (let key of Object.keys(replacements)) {
		template = template.replace(RegExp(`%%${Case.upper(key, '_')}.*?%%`, 'g'), `%%${Case.upper(replacements[key], '_')}%%`);
		keys[keys.indexOf(key)] = replacements[key];
	}

	let templates = { preview: template, pdfData: template };

	for (let key of keys) {
		if (key.endsWith('surname')) {
			templates = transformSurnameFields(templates, key, substitutions, isDraft, requiredFieldsFilled);
		}
		if (['obtaining_method', 'recipient_of_accounting_statement'].includes(key)) {
			templates = transformObtainingMethodFields(templates, key, substitutions[key]);
		}
		if (/(BANK_ACCOUNTS_LIST%%|BANK_ACCOUNTS_INLINE_LIST%%|%%MAIN_BANK_ACCOUNT%%)/.test(templates.preview)) {
			templates = insertBankAccounts(templates, key, substitutions, isDraft, transmitter);
		}
		if (/judicial_act_date/.test(key)) {
			key = 'judicial_act_date';
		}
		templates = transformRegistrarFields(templates, key, substitutions, isDraft, requiredFieldsFilled);

		// эти 3 строки для сохранения fieldText равным нулю, простое || трактует 0 как false
		let fieldText = currentData[Case.camel(key)];
		if (!fieldText && fieldText !== 0) {
			fieldText = substitutions[key];
		}
		if (!fieldText && fieldText !== 0) {
			fieldText = derivedData[Case.camel(key)];
		}

		if (/percent/.test(key) || /external_factor_/.test(key) || /internal_factor_/.test(key)) {
			templates = transformPercentFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		}
		if (
			/(amount|balance|monthly_expenses_of_debtor_|debt_to_creditors_from_statement_of_debtor)/.test(key) ||
			[
				'book_property_value',
				'market_property_value',
				'monthly_expenses',
				'debt_to_creditors_from_statement_of_receivable',
				'living_wage_for_bankrupt',
				'free_funds_of_bankrupt',
			].includes(key)
		) {
			templates = transformAmountFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		}
		if (key == 'meeting_questions') {
			templates = transformMeetingQuestions(templates, isDraft ? fieldText : substitutions[key]);
			continue;
		}
		if (key == 'meeting_solutions') {
			templates = transformMeetingSolutions(templates, isDraft ? fieldText : substitutions[key]);
			continue;
		}
		if (
			[
				'prolongations',
				'prolongations_resolution',
				'renewal_period',
				'completion_date_after_renewal',
				'completion_date_after_renewal_or_completion_date',
			].includes(key)
		) {
			fieldText = transmitter.procedure.prolongations;
		}
		if (/%%SIGNATURE%%/.test(templates.preview) && substitutions.signature) {
			templates = transformSignature(templates, transmitter.procedure.arbitr_manager.signature);
		}
		if (['appendices', 'appendices_list'].includes(key) && /%%(APPENDICES_LIST|APPENDICES)%%/.test(templates.preview)) {
			templates = transformAppendices(templates, substitutions, transmitter.procedure, isDraft);
		}

		templates = transformUniqueFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		templates = transformOtherFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		templates = transformOptionalParagraph(templates, substitutions.insert_first_paragraph);
	}

	templates = clearFields(templates, isDraft);

	return templates;
};

const transformTemplate = ({
	transmitter,
	substitutions,
	currentData,
	requiredFieldsFilled,
	creditorsRequirements,
	allPossessions,
	actualMeasures,
	triggers,
	derivedData,
	template,
	additionalTemplateFragments,
	isDraft,
	measureKinds,
	bankruptCategories,
	undeclaredDebts,
	tablesData,
	dct,
}) => {
	const replacements = {
		region_id: 'region',
		realty_location_id: 'realty_location',
		sro_id: 'sro_full_name',
		correspondent_id: 'correspondent_short_name',
		court_id: 'court_full_name',
		appendices: 'appendices_list',
	};

	const keys = [
		...new Set([...dct.form_fields.map(key => Case.snake(key)), ...Object.keys(substitutions), ...Object.keys(derivedData).map(key => Case.snake(key))]),
	];

	for (let key of Object.keys(replacements)) {
		template = template.replace(RegExp(`%%${Case.upper(key, '_')}.*?%%`, 'g'), `%%${Case.upper(replacements[key], '_')}%%`);
		keys[keys.indexOf(key)] = replacements[key];
	}

	let templates = { preview: template, pdfData: template };

	// расставляем фрагменты шаблона в соответствии с триггерами
	templates = transformTriggers(templates, triggers);
	// удаляем лишнюю стилизацию от MS необходимую для оформления документа как текста электронного письма
	if (transmitter.dct.category == 'meetings') {
		templates = reworkingMeetingStyles(templates);
	} else {
		templates = reworkingStyles(templates);
	}
	// определяемся с названием АУ для текущего документа: 'временным' или 'арбитражным'
	templates = transformArbitrManagerAdjectiveFields(templates, requiredFieldsFilled);
	// подставляем текст категории банкрота
	templates = transformBankruptCategoryFields(templates, substitutions, bankruptCategories, isDraft, requiredFieldsFilled);
	// TODO: переделать в триггер
	if (substitutions.form_providing_information) {
		templates = transformFormProvidingInformation(templates, substitutions, isDraft);
	}
	// заполняем таблицы
	for (let resourceName in tablesData) {
		templates = transformTable(templates, resourceName, tablesData[resourceName], isDraft);
	}

	// заменяем конструкцию if-endif у темплейтов собраний
	templates = transformMeetingClauses(templates, substitutions);
	// заменяем все поля собрания
	templates = transformMeetingFields(templates, substitutions, isDraft);

	const possessionsSubjectsKeys = Object.keys(allPossessions).filter(key => /^(possessionsSubject|possessionsBankAccounts|possessionsReceivables).*/.test(key));

	for (let key of possessionsSubjectsKeys) {
		if (RegExp(`%%${Case.upper(key, '_')}.*?%%`, 'g').test(templates.preview)) {
			templates = transformPersonPossessionsTable(templates, Case.upper(key, '_'), allPossessions[key]);
		}
	}

	if (/%%NUMBER_MEETINGS%%/.test(templates.preview)) {
		templates = transformOtherFields(
			templates,
			'number_meetings',
			((transmitter.meetings && transmitter.meetings.creditors) || []).length,
			isDraft,
			requiredFieldsFilled,
		);
	}
	if (/%%(APPENDICES_LIST|APPENDICES)%%/.test(templates.preview)) {
		templates = transformAppendices(templates, substitutions, transmitter.procedure, isDraft);
	}
	if (/%%NUMBER_WORKER_MEETINGS%%/.test(templates.preview)) {
		templates = transformOtherFields(
			templates,
			'number_worker_meetings',
			((transmitter.meetings && transmitter.meetings.employees) || []).length,
			isDraft,
			requiredFieldsFilled,
		);
	}
	if (/%%BANK_ACCOUNTS_CLOSURE_RESULTS%%/.test(templates.preview)) {
		templates = transformBankAccountsBlock(templates, additionalTemplateFragments, transmitter.bankAccounts, isDraft);
	}
	if (/%%SPECIALISTS%%/.test(templates.preview)) {
		templates = transformSpecialistsBlock(templates, additionalTemplateFragments, transmitter.specialists, isDraft);
	}
	if (/%%SPOUSES%%/.test(templates.preview)) {
		const spouses = currentData.spousesIds ? (transmitter.spouses || []).filter(spouse => currentData.spousesIds.includes(spouse.id)) : transmitter.spouses;
		templates = transformSpousesBlock(templates, spouses, isDraft);
	}
	if (/%%MINOR_CHILDREN_OF_BANKRUPT%%/.test(templates.preview)) {
		templates = transformChildrenBlock(templates, transmitter.children, substitutions.minor_children_of_bankrupt, isDraft);
	}
	if (/(%%WORKING_EMPLOYEES%%|%%DISMISSED_EMPLOYEES%%)/.test(templates.preview)) {
		templates = transformEmployeesBlock(templates, additionalTemplateFragments, transmitter.employees, isDraft);
	}
	if (/%%COMPLAINTS%%/.test(templates.preview)) {
		templates = transformComplaintsBlock(templates, additionalTemplateFragments, transmitter.complaints, isDraft);
	}
	if (/%%MEETINGS%%/.test(templates.preview) && transmitter.meetings) {
		templates = transformCreditorMeetingsBlock(templates, additionalTemplateFragments, transmitter.meetings.creditors, isDraft);
	}
	if (/%%WORKER_MEETINGS%%/.test(templates.preview) && transmitter.meetings) {
		templates = transformWorkerMeetingsBlock(templates, additionalTemplateFragments, transmitter.meetings.employees, isDraft);
	}
	if (/%%MEASURES%%/.test(templates.preview)) {
		templates = transformMeasuresBlock(templates, additionalTemplateFragments, transmitter.measures, measureKinds, isDraft);
	}
	if (/%%MEASURES_TEXT%%/.test(templates.preview)) {
		templates = transformMeasuresTextBlock(templates, actualMeasures);
	}
	if (/%%SENT_NOTIFICATION_REQUESTS%%/.test(templates.preview)) {
		templates = transformSentNotificationsRequestsBlock(templates, additionalTemplateFragments, transmitter.sentNotificationsRequests, isDraft);
	}
	if (/%%ANSWERS%%/.test(templates.preview)) {
		templates = transformAnswersBlock(templates, additionalTemplateFragments, transmitter.answers, isDraft);
	}
	if (/%%ANSWERS_FNS_PFR_FSS%%/.test(templates.preview)) {
		const answers = transmitter.answers && transmitter.answers.filter(answer => ['fns', 'pfr', 'fss'].includes(answer.correspondent_kind));
		templates = transformAnswersFnsPfrFssBlock(templates, answers, isDraft);
	}
	if (/%%ANSWERS_ABOUT_PROPERTY%%/.test(templates.preview)) {
		const answers =
			transmitter.answers &&
			transmitter.answers.filter(answer =>
				['gibdd', 'mchs', 'gtn', 'mvd', 'fsis', 'favt', 'bti', 'fns', 'rosgvardiya', 'rosreestr'].includes(answer.correspondent_kind),
			);
		templates = transformAnswersAboutPropertyList(templates, answers);
	}
	if (/%%RECEIVABLES%%/.test(templates.preview)) {
		templates = transformReceivablesBlock(templates, additionalTemplateFragments, allPossessions.possessionsReceivables, isDraft);
	}
	if (/%%ASSETS.*?%%/.test(templates.preview)) {
		let assets = { ...allPossessions, other: allPossessions.other.filter(procession => !procession.onlyResidence) };
		templates = transformPersonAssetsTable(templates, assets);
	}
	if (/%%LIABILITIES.*?%%/.test(templates.preview)) {
		templates = transformPersonLiabilitiesTable(templates, creditorsRequirements.allBesidesExcluded, undeclaredDebts);
	}
	if (/%%RESPONSIBILITIES.*?%%/.test(templates.preview)) {
		templates = transformPersonResponsibilitiesTable(templates, creditorsRequirements.allBesidesExcluded, undeclaredDebts);
	}
	if (/%%REALTY.*?%%/.test(templates.preview)) {
		const possessions = [
			...allPossessions.possessionsNoPledgeSubjectsLand,
			...allPossessions.possessionsNoPledgeSubjectsResidential,
			...allPossessions.possessionsNoPledgeSubjectsNonResidential,
			...allPossessions.possessionsPledgeSubjectsLand,
			...allPossessions.possessionsPledgeSubjectsResidential,
			...allPossessions.possessionsPledgeSubjectsNonResidential,
		];
		templates = transformPersonPossessionsTable(templates, 'REALTY', possessions);
	}
	if (/%%MOVABLES.*?%%/.test(templates.preview)) {
		const possessions = [...allPossessions.possessionsNoPledgeSubjectsTransport, ...allPossessions.possessionsPledgeSubjectsTransport];
		templates = transformPersonPossessionsTable(templates, 'MOVABLES', possessions);
	}
	if (/%%SHARES.*?%%/.test(templates.preview)) {
		const possessions = [...allPossessions.possessionsNoPledgeSubjectsShare, ...allPossessions.possessionsPledgeSubjectsShare];
		templates = transformPersonPossessionsTable(templates, 'SHARES', possessions);
	}
	if (/%%OTHER_POSSESSIONS.*?%%/.test(templates.preview)) {
		const possessions = [...allPossessions.possessionsNoPledgeSubjectsOther, ...allPossessions.possessionsPledgeSubjectsOther];
		templates = transformPersonPossessionsTable(templates, 'OTHER_POSSESSIONS', possessions);
	}
	if (/%%BANK_ACCOUNTS((?!_LIST).)*?%%/.test(templates.preview)) {
		templates = transformPersonPossessionsTable(templates, 'BANK_ACCOUNTS', transmitter.bankAccounts);
	}
	if (/%%INCOME_SOURCES.*?%%/.test(templates.preview)) {
		templates = transformIncomeSourcesTable(templates, transmitter.incomeSources, isDraft);
	}
	if (/%%SIGNATURE%%/.test(templates.preview) && substitutions.signature) {
		templates = transformSignature(templates, transmitter.procedure.arbitr_manager.signature);
	}
	if (/%%GRAND_TOTAL_RUNNING_COST%%/.test(templates.preview) || /%%.*?_RUNNING_COST_STAGE%%/.test(templates.preview)) {
		templates = transformRunningCostsBlock(templates, transmitter);
	}
	if (/%%RECEIPTS%%/.test(templates.preview)) {
		templates = transformReceiptsBlock(templates, transmitter);
	}
	if (/%%PAYOUTS%%/.test(templates.preview)) {
		templates = transformPayoutsBlock(templates);
	}
	if (/%%RECEIPTS_AND_PAYOUTS%%/.test(templates.preview)) {
		templates = transformReceiptsPayoutBlock(templates, transmitter);
	}
	for (let key of ['analisys_conclusion', 'arbitrary_text']) {
		let value = substitutions[key];
		if (key == 'arbitrary_text') {
			value = isPresent(substitutions[key]) ? substitutions[key] : substitutions['dct_arbitrary_text'];
		}
		templates = transformArbitraryText(templates, key, value, isDraft, requiredFieldsFilled[key]);
	}
	if (/%%ADDITIONAL_ARBITRARY_TEXT%%/.test(templates.preview)) {
		templates = transformAdditionalArbitraryText(templates, substitutions['arbitrary_text'], isDraft);
	}
	if (/%%BANKRUPT_INFO%%/.test(templates.preview)) {
		templates = transformBankruptInfo(templates, transmitter.procedure, isDraft);
	}
	if (/%%CREDITORS_FIRST_MEETING_DATE%%/.test(templates.preview)) {
		templates = transformUniqueFields(
			templates,
			'creditors_first_meeting_date',
			isDraft
				? currentData.creditorsFirstMeetingMinutesDate
				: currentData.creditorsFirstMeetingMinutesDate || '<div style="width: 100%; text-align: center;">-</div>',
			isDraft,
			requiredFieldsFilled,
		);
	}
	if (/%%CREDITORS_FIRST_MEETING_AGENDA%%/.test(templates.preview)) {
		templates = transformOtherFields(
			templates,
			'creditors_first_meeting_agenda',
			isDraft ? currentData.creditorsFirstMeetingAgenda : currentData.creditorsFirstMeetingAgenda || '<div style="width: 100%; text-align: center;">-</div>',
			isDraft,
			requiredFieldsFilled,
		);
	}
	if (/%%CREDITORS_FIRST_MEETING_SOLUTIONS%%/.test(templates.preview)) {
		templates = transformOtherFields(
			templates,
			'creditors_first_meeting_solutions',
			isDraft
				? currentData.creditorsFirstMeetingSolutions
				: currentData.creditorsFirstMeetingSolutions || '<div style="width: 100%; text-align: center;">-</div>',
			isDraft,
			requiredFieldsFilled,
		);
	}
	if (/%%CREDITORS_FIRST_MEETING_ARBITR_PROPOSALS%%/.test(templates.preview)) {
		templates = transformOtherFields(
			templates,
			'creditors_first_meeting_arbitr_proposals',
			isDraft
				? currentData.creditorsFirstMeetingArbitrProposals
				: currentData.creditorsFirstMeetingArbitrProposals || '<div style="width: 100%; text-align: center;">-</div>',
			isDraft,
			requiredFieldsFilled,
		);
	}

	const substitutionsKeys = Object.keys(substitutions);
	const derivedDataKeys = Object.keys(derivedData).map(key => Case.snake(key));
	//const keys = [...dct.form_fields.map(key => Case.snake(key)), ...substitutionsKeys, ...derivedDataKeys];

	for (let key of keys) {
		if (key.endsWith('surname')) {
			templates = transformSurnameFields(templates, key, substitutions, isDraft, requiredFieldsFilled);
		}
		if (['obtaining_method', 'recipient_of_accounting_statement'].includes(key)) {
			templates = transformObtainingMethodFields(templates, key, substitutions[key]);
		}
		if (/(BANK_ACCOUNTS_LIST%%|BANK_ACCOUNTS_INLINE_LIST%%|%%MAIN_BANK_ACCOUNT%%)/.test(templates.preview)) {
			templates = insertBankAccounts(templates, key, substitutions, isDraft, transmitter);
		}
		if (/judicial_act_date/.test(key)) {
			key = 'judicial_act_date';
		}
		templates = transformRegistrarFields(templates, key, substitutions, isDraft, requiredFieldsFilled);

		// эти 3 строки для сохранения fieldText равным нулю, простое || трактует 0 как false
		let fieldText = currentData[Case.camel(key)];
		if (substitutionsKeys.includes(key) && !fieldText && fieldText !== 0) {
			fieldText = substitutions[key];
		}
		if (derivedDataKeys.includes(key) && !fieldText && fieldText !== 0) {
			fieldText = derivedData[Case.camel(key)];
		}

		if (/percent/.test(key) || /external_factor_/.test(key) || /internal_factor_/.test(key)) {
			templates = transformPercentFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		}
		if (
			/(amount|balance|monthly_expenses_of_debtor_|debt_to_creditors_from_statement_of_debtor|living_wage_for)/.test(key) ||
			['book_property_value', 'market_property_value', 'monthly_expenses', 'debt_to_creditors_from_statement_of_receivable', 'free_funds_of_bankrupt'].includes(
				key,
			)
		) {
			templates = transformAmountFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		}
		if (key == 'meeting_questions') {
			templates = transformMeetingQuestions(templates, isDraft ? fieldText : substitutions[key]);
			continue;
		}
		if (key == 'meeting_solutions') {
			templates = transformMeetingSolutions(templates, isDraft ? fieldText : substitutions[key]);
			continue;
		}
		if (
			[
				'prolongations',
				'prolongations_resolution',
				'renewal_period',
				'completion_date_after_renewal',
				'completion_date_after_renewal_or_completion_date',
			].includes(key)
		) {
			fieldText = transmitter.procedure.prolongations;
		}
		if (['arbitr_manager_insurance_orgs', 'arbitr_manager_insurance_policies'].includes(key)) {
			const procedure = transmitter.procedure;
			const introductionDates = [procedure.resolution_introduction_date, procedure.introduction_date].filter(i => i);
			const dateStart = isPresent(introductionDates) ? introductionDates.reduce((min, c) => (c < min ? c : min)) : '2000-01-01';
			const completions = [procedure.completion_date, ...toArray(procedure.prolongations).map(p => p.completion_date)].filter(i => i);
			const dateFinish = isPresent(completions) ? completions.reduce((max, c) => (c > max ? c : max)) : '2100-12-31';

			fieldText = toArray(transmitter.insurancePolicies).filter(
				p =>
					p.proprietor_type == 'ArbitrManager' &&
					((isPresent(p.date_start) ? moment(p.date_start).isBetween(dateStart, dateFinish, undefined, '[]') : true) ||
						(isPresent(p.date_finish) ? moment(p.date_finish).isBetween(dateStart, dateFinish, undefined, '[]') : true) ||
						(isPresent(p.date_start) && isPresent(p.date_finish)
							? moment(dateStart).isBetween(p.date_start, p.date_finish, undefined, '[]') ||
								moment(dateFinish).isBetween(p.date_start, p.date_finish, undefined, '[]')
							: true)),
			);
		}

		templates = transformUniqueFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		templates = transformOtherFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		templates = transformOptionalParagraph(templates, substitutions.insert_first_paragraph);
	}

	templates = clearFields(templates, isDraft);

	//templates = transformTriggers(templates, triggers)

	return templates;
};
