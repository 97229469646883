<!-- @format -->
<script>
	import { fieldsReady } from 'base_stores';
	import { currentData, commonParams, substitutions, creditorsRequirements } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import { isBlank } from 'utils/tools';

	export let fieldId;

	$: localCommonParams = { ...$commonParams, disabled: $commonParams.disabled || true };

	let forceSave = false;
	let initFieldReady = true;

	$: $fieldsReady[fieldId] = !initFieldReady;

	$: if (
		$creditorsRequirements &&
		$creditorsRequirements.numberCounterparties &&
		isBlank($substitutions && $substitutions.number_lenders_included_in_requirements_reestr)
	) {
		forceSave = true;
	} else {
		forceSave = false;
		initFieldReady = false;
	}

	$: numberLendersIncludedInRequirementsReestr = {
		...localCommonParams,
		attribute: 'number_lenders_included_in_requirements_reestr',
		label: 'Количество кредиторов, включенных в РТК',
		value: $currentData?.numberLendersIncludedInRequirementsReestr,
		placeholder: '5',
		hintData: { freeMessage: 'AI заполнил из РТК' },
		forceSave: forceSave,
	};
</script>

<AutosaveInput {...numberLendersIncludedInRequirementsReestr} />
