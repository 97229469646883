<!-- @format -->
<script>
	import { onMount } from 'svelte'
	import { procedure, transmitter, options, bankruptIsPerson, bankruptIsOrganization } from 'base_stores'
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { showModal } from '~/svelte/components/modal.svelte'
	import { fetchGet } from 'utils/fetch'
	import { isPresent, formatSum } from 'utils/tools'
	import { currencyFormat } from 'utils/formats'
	import BankAccountModal from './_modal.svelte'

	export let relevanceDate;
	export let bankAccountId
	export let showMain = true
	export let mounted = false

	let bankAccounts = []

	$: procedurePhase = $procedure && $procedure.phase || procedurePhase

	const modalId = 'form-bank_account-modal';
	const tableTitles = ['Наименование банка/брокера', 'Вид счёта', 'Номер счёта', 'Остаток на начало', 'Текущий остаток', 'Валюта'];
	const optionalTableTitles = ['Предпринятые меры', 'Результат'];

	const getType = (id, data) => {
		return data && data.map(value => (value.id === id ? value.text : '')).join('');
	};

	const openBankAccountModalform = (id) => {
		bankAccountId = id
		showModal(modalId)
	}

	const accountName = account => {
		return account.electronic_payment_service || (account.counterparty_name ? account.counterparty_name : account.bank_name) || '';
	};

	const loadBankAccounts = async (relevanceDate) => {
		const response = await fetchGet('/api/private/bank_accounts', { date: relevanceDate })
		$transmitter = { ...$transmitter, bankAccounts: response.bank_accounts }
		bankAccounts = $transmitter.bankAccounts
	}

	$: if (relevanceDate) {
		loadBankAccounts(relevanceDate);

		localStorage.setItem('relevanceDate', JSON.stringify({ value: relevanceDate, expire: moment().endOf('day') }));
	}

	onMount(async () => {
		let response = await fetchGet('/api/private/bank_accounts/kind_options')
		$options = { ...$options, bankAccountKind: response.options }
		await loadBankAccounts()
		mounted = true
	})
</script>

<table class="table table-hover table-mobile m-0 border-collapse_separate">
	<thead class="thead-light">
		<tr>
			{#each tableTitles as title}
				<th class="text-center p-w-xs">{title}</th>
			{/each}
			{#if ['property_realization', 'bankruptcy_proceedings'].includes(procedurePhase)}
				{#each optionalTableTitles as optionalTitle}
					<th class="text-center p-w-xs">{optionalTitle}</th>
				{/each}
			{/if}
		</tr>
	</thead>
	<tfoot>
		<tr>
			<th class="text-left">Итого (руб.):</th>
			<th></th>
			<th></th>
			<th class="text-right">{(bankAccounts && formatSum(bankAccounts.reduce((sum, e) => sum + parseFloat(e.balance_rub), 0.0))) || 0.0}</th>
			<th class="text-right">{(bankAccounts && formatSum(bankAccounts.reduce((sum, e) => sum + parseFloat(e.current_balance_rub), 0.0))) || 0.0}</th>
			<th></th>
			{#if ['property_realization', 'bankruptcy_proceedings'].includes(procedurePhase)}
				{#each optionalTableTitles as _optionalTitle}
					<th></th>
				{/each}
			{/if}
		</tr>
	</tfoot>
	<tbody class="text-center">
		{#if mounted}
			{#if isPresent(bankAccounts)}
				{#each bankAccounts as account}
					<tr
						class="cursor_pointer"
						on:click={(($bankruptIsPerson && $procedureAvailable) || $bankruptIsOrganization) && (() => openBankAccountModalform(account.id))}
					>
						<td class="text-left">{accountName(account)}</td>
						<td class="text-left">
							{getType(account.kind, $options.bankAccountKind)}
							{#if account.is_main && showMain}
								<span class="badge badge-primary"> Основной </span>
							{/if}
						</td>
						<td class="text-center">{account.number || ''}</td>
						<td class="text-right">{account.balance ? currencyFormat(account.balance) : ''}</td>
						<td class="text-right">{account.current_balance ? currencyFormat(account.current_balance) : ''}</td>
						<td class="text-center">{account.currency_type || ''}</td>
						{#if ['property_realization', 'bankruptcy_proceedings'].includes(procedurePhase)}
							<td class="text-left">{account.measures_taken || ''}</td>
							<td class="text-left">{account.result || ''}</td>
						{/if}
					</tr>
				{/each}
			{:else}
				<tr><td class="text-secondary" colspan="7">Нет данных</td></tr>
			{/if}
		{:else}
			<tr><td class="text-secondary" colspan="7"><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
		{/if}
	</tbody>
</table>

<BankAccountModal
	{modalId}
	bind:bankAccountId
	on:create={loadBankAccounts}
	on:update={loadBankAccounts}
	on:delete={loadBankAccounts}
/>
