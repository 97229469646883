<!-- @format -->
<script>
	import { procedure, transmitter, bankruptIsPerson, bankruptIsOrganization, options } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch';
	import Header from '~/svelte/components/header.svelte';
	import { showModal } from '~/svelte/components/modal.svelte';
	import MeasuresModal from './_modal.svelte';

	export let measures;

	const tableTitles = ['№ п/п', 'Реализованные меры', 'Итоги реализации мер'];

	let procedureId = null;
	let mounted = false;
	let measureKinds = [];
	let measureId = null;
	let noMeasureSelected = true;

	$: measures = ($transmitter && $transmitter.measures) || measures || [];

	const openMeasureModalForm = id => {
		measureId = id;
		showModal('form-measure-modal');
	};

	onMount(() => {
		procedureId = $procedure && $procedure.id;

		fetchGet('/api/private/measures/get_measure_kinds')
			.then(response => {
				measureKinds = response.measure_kinds;
				$options.measureKind = [
					...response.measure_kinds.map(measure => {
						return { id: measure.id, text: measure.description };
					}),
				];
			})
			.then(() => fetchGet('/api/private/measures'))
			.then(response => ($transmitter = { ...$transmitter, measures: response.measures }))
			.then(() => fetchGet('/api/private/courts/options'))
			.then(response => ($options.court = response.options))
			.then(() => (mounted = true));
	});
</script>

<Header><span slot="name">Меры по сохранности имущества</span></Header>
<div class="m-b-lg mb-0">
	<button
		class="btn btn-sm btn-warning m-r-sm m-b-sm ml-2 ml-sm-0"
		disabled={!$procedureAvailable || !procedureId}
		on:click={procedureId && $procedureAvailable && openMeasureModalForm(null)}
	>
		+ Мера
	</button>
</div>
<div class="m-b-lg overlay p-1 footer-margin">
	<table class="table table-stripped table-hover m-0">
		<thead class="thead-light">
			<tr>
				{#each tableTitles as title}
					<th class="text-center p-w-xs">{title}</th>
				{/each}
			</tr>
		</thead>
		<tbody class="text-center">
			{#each measureKinds as measureKind, index}
				<tr>
					<td class="bold-text table-number_width">{index + 1}</td>
					<td class="bold-text text-left" colspan="2">{measureKind.description}</td>
				</tr>
				{#if mounted && measures}
					{#each [...(measures[measureKind.id] || [])] as measure, measureIndex}
						<tr on:click={(($bankruptIsPerson && $procedureAvailable) || $bankruptIsOrganization) && openMeasureModalForm(measure.id)}>
							<td class="table-number_width">{index + 1}.{measureIndex + 1}</td>
							<td class="text-left">{measure.name || ''}</td>
							<td class="text-left">{measure.result || ''}</td>
						</tr>
					{/each}
					{#if !measures[measureKind.id]}
						<tr class="text-secondary">
							<td colspan="3">Нет данных</td>
						</tr>
					{/if}
				{:else}
					<tr><td class="text-secondary" colspan="3"><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
				{/if}
			{/each}
		</tbody>
	</table>
</div>
<MeasuresModal {measureId} bind:noMeasureSelected />

<style>
	table,
	td {
		border-collapse: separate;
		cursor: pointer;
	}

	.bold-text {
		font-weight: 700 !important;
		color: #495057 !important;
	}

	.table-number_width {
		width: 60px;
	}
</style>
