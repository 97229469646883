<!-- @format -->
<script>
	import { onMount, createEventDispatcher } from 'svelte'
	import { transmitter, options, bankruptIsPerson, bankruptIsOrganization } from 'base_stores'
	import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch'
	import Datepicker from '~/svelte/components/ui/datepicker.svelte'
	import Select2 from '~/svelte/components/ui/select2.svelte'
	import InputCurrency from '~/svelte/components/ui/input_currency.svelte'
	import Input from '~/svelte/components/ui/input.svelte'
	import { showModal, hideModal } from '~/svelte/components/modal.svelte'
	import Textarea from '~/svelte/components/ui/textarea.svelte'
	import RadioSelect from '~/svelte/components/ui/radio_select.svelte'
	import Checkbox from '~/svelte/components/ui/checkbox.svelte'
	import { isPresent, isBlank, toArray, formatSum, formatDate, truncateString } from 'utils/tools'
	import { clearScans } from '~/svelte/_shared/scans_sets/scans_set.js'
	import ReceiptModal from '~/svelte/receipts/_modal.svelte'
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte'
	import ScansSet from '~/svelte/_shared/scans_sets/_set_field.svelte'

	export let noCounterpartySelected;
	export let handleSubmit;
	export let handleCancel;
	export let handleDelete;
	export let disabled = false;

	const dispatch = createEventDispatcher();

	const nullReceivable = {
		counterparty_id: null,
		debt_amount: 0.0,
		note: null,
		valuer: 'arbitr_manager',
		cost: 0.0,
		excluded_from_bankruptcy: null,
		exclusion_grounds: null,
		is_pledge_subject: null,
		paid_sum: 0.0,
		receipts: [],
		buy_date: null,
		sale_date: null,
		sale_cost: 0.0,
		creditors_requirement_ids: [],
	};

	let isNewInstance
	let counterpartyId;
	let tempCounterparty = null;
	let receivable = { ...nullReceivable };
	let receiptModal;

	$: debitOption = toArray($options.possessionKind).find(kind => kind.code === 'debit');
	$: subkindOptions = debitOption && $options.possessionKind.filter(kind => String(kind.parent_id) === String(debitOption.id));
	$: method = isPresent($transmitter.receivableId) ? fetchPut : fetchPost;
	$: noCounterpartySelected = !receivable.counterparty_id || !receivable.valuer || (!receivable.kind_id && $bankruptIsOrganization);

	$: if ($transmitter.receivableId && $transmitter.receivableId !== receivable.id) {
		getReceivable();
	}

	const getReceivable = () => {
		fetchGet(`/api/private/receivables/${$transmitter.receivableId}`).then(response => {
			receivable = response.receivable;
			$transmitter.runningCosts = response.running_costs;
		});
	};

	$: if (!receivable) {
		receivable = { ...nullReceivable };
	}

	$: if (!receivable.excluded_from_bankruptcy) {
		receivable.exclusion_grounds = null;
	}

	$: formFields = {
		kindId: {
			required: $bankruptIsOrganization,
			model: 'receivable',
			attribute: 'kind_id',
			placeholder: 'Выбрать подвид',
			label: 'Подвид имущества',
			options: subkindOptions,
			disabled,
		},
		counterpartyId: {
			required: true,
			model: 'receivable',
			attribute: 'counterparty_id',
			placeholder: 'Выбрать дебитора',
			label: 'Наименование дебитора',
			checkSuitability: checkSuitabilityCounterparty,
			actionInUnsuitability: actionInUnsuitabilityCounterparty,
			modify: 'showModalCounterpartyForm',
			options: $options.counterpartyDebtor,
			disabled,
		},
		debtAmount: {
			model: 'receivable',
			attribute: 'debt_amount',
			placeholder: '0,00',
			label: 'Сумма задолженности (руб.)',
			disabled,
		},
		fundsFromDebtCollection: {
			model: 'receivable',
			attribute: 'paid_sum',
			placeholder: '0,00',
			disabled: true,
			label: 'Средства, полученные от взыскания задолженности (руб.)',
		},
		debtContent: {
			model: 'receivable',
			attribute: 'debt_content',
			label: 'Содержание обязательства',
			placeholder: 'Введите содержание обязательства',
			disabled,
		},
		debtJustification: {
			model: 'receivable',
			attribute: 'debt_justification',
			label: 'Основание возникновения',
			placeholder: 'Введите основание возникновения',
			disabled,
		},
		note: {
			model: 'receivable',
			attribute: 'note',
			placeholder: 'Направлена претензия 00.00.0000 г.',
			label: 'Меры, принятые по взысканию',
			disabled,
		},
		cost: {
			model: 'receivable',
			attribute: 'cost',
			placeholder: '0,00',
			label: 'Стоимость (руб.)',
			disabled,
		},
		valuer: {
			required: true,
			model: 'receivable',
			attribute: 'valuer',
			options: $options.valuer,
			label: 'Кто определил стоимость',
			disabled,
		},
		excludedFromBankruptcy: {
			model: 'receivable',
			attribute: 'excluded_from_bankruptcy',
			text: 'да, исключен',
			label: 'Исключен из конкурсной массы',
			disabled,
		},
		exclusionGrounds: {
			model: 'receivable',
			attribute: 'exclusion_grounds',
			label: 'Основание исключения',
			disabled,
		},
		isPledgeSubject: {
			model: 'receivable',
			attribute: 'is_pledge_subject',
			text: 'да, является',
			label: 'Является предметом залога',
			disabled,
		},
		buyDate: {
			model: 'receivable',
			attribute: 'buy_date',
			label: 'Дата покупки (постановки на учёт)',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			disabled,
		},
		saleDate: {
			model: 'receivable',
			attribute: 'sale_date',
			label: 'Дата продажи (снятия с учёта)',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			disabled,
		},
		saleCost: {
			model: 'receivable',
			attribute: 'sale_cost',
			placeholder: '0,00',
			label: 'Цена продажи (руб.)',
			disabled,
		},
		creditorsRequirementIds: {
			model: 'receivable',
			attribute: 'creditors_requirement_ids',
			placeholder: 'Выбрать требование',
			label: 'Залоговые требования',
			options: $options.creditorsRequirements,
			multiple: true,
			disabled,
		},
	};

	$: filledRequiredFields = isPresent(receivable.counterparty_id) && Number.isInteger(+receivable.counterparty_id) &&
		(isPresent(subkindOptions) ? isPresent(receivable.kind_id) : true) &&
		isPresent(receivable.valuer)

	$: if (isBlank($transmitter.receivableId) && filledRequiredFields) { secretlyСreate() }

	const secretlyСreate = async () => {
		const response = await fetchPost('/api/private/receivables', { receivable })
		$transmitter.receivables = response?.receivables
		$transmitter.receivableId = response?.receivable_id
	}

	const refreshScansSet = ({detail: scansSet}) => {
		$transmitter.receivables = $transmitter.receivables.map(s => s.id == $transmitter.receivableId ? { ...s, scans_set: scansSet } : s)
		receivable.scans_set = scansSet
	}

	$: if (receivable && receivable.receipts) receivable.paid_sum = receivable.receipts.reduce((s, e) => s += parseFloat(e.sum), 0)

	handleSubmit = async () => {
		if (isBlank(receivable.kind_id)) { receivable.kind_id = debitOption.id }

		const response = await method(`/api/private/receivables${$transmitter.receivableId ? `/${$transmitter.receivableId}` : ''}`, { receivable } )
		$transmitter = { ...$transmitter, receivables: response.receivables }

		const scansSet = await clearScans(receivable.scans_set, 'deleted')
		$transmitter.receivables = $transmitter.receivables.map(s => s.id == $transmitter.receivableId ? { ...s, scans_set: scansSet } : s)

		dispatch($transmitter.receivableId ? 'update' : 'create', response.receivable_id)
		//$transmitter.receivableId = null
		hideModal('form-receivable-modal')
	}

	handleDelete = () => {
		return fetchDelete(`/api/private/receivables/${$transmitter.receivableId}`)
			.then(_response => {
				$transmitter.receivables = $transmitter.receivables.filter(r => r.id != $transmitter.receivableId)
				dispatch('delete')
			})
	}

	handleCancel = () => {
		if (isNewInstance) {
			if (isPresent($transmitter.receivableId)) { handleDelete() }
		} else {
			clearScans(receivable.scans_set, 'new')
		}

		receivable = { ...nullReceivable }
		//$transmitter.receivableId = null
	}

	const checkSuitabilityCounterparty = (item) => {
		let counterparty = $options.counterpartyDebtor?.find(opt => opt.id == item.id)
		if (isPresent(counterparty?.roles)) {
			return counterparty.roles.includes('debtor');
		} else {
			return true;
		}
	};

	const actionInUnsuitabilityCounterparty = async item => {
		let correspondent = $options.counterpartyDebtor.find(opt => opt.id == item.id);
		let roles = [...correspondent.roles, 'debtor'];
		await fetchPut(`/api/private/counterparties/${correspondent.id}`, { roles: roles });
		$options.counterpartyDebtor.find(opt => opt.id == item.id).roles = roles;
	};

	const handleEditRunningCostItem = id => dispatch('editRunningCost', id);

	const showModalCounterpartyForm = value => {
		if (value === 'new') {
			tempCounterparty = receivable.counterparty_id;
			openCounterpartyModalForm();
		}
	};

	const openCounterpartyModalForm = id => {
		counterpartyId = id;
		showModal('form-counterparty-modal');
	};

	const handleEditReceiptItem = id => receiptModal.editItem(id);

	const handleFormReceiptUpdated = ({ detail: result }) => {
		const row = receivable.receipts.find(el => el.id == result.item.id);
		row.sum = result.item.sum;
		row.date_at = result.item.date_at;
		receivable.receipts = receivable.receipts;
	};

	const handleFormReceiptDeleted = ({ detail: id }) => (receivable.receipts = receivable.receipts.filter(row => row.id != id));

	$: if ($transmitter && $transmitter.counterpartyFormCanceled) {
		receivable.counterparty_id = tempCounterparty;
		$transmitter.counterpartyFormCanceled = false;
		tempCounterparty = null;
	}

	const getOptions = async () => {
		const response = await fetchGet('/api/private/counterparties/get_options_for_roles', { possible_roles: ['debtor'] });
		$options.counterpartyDebtor = [{ id: null, text: '' }, { id: 'new', text: 'Добавить дебитора' }, ...response.options];
	};

	const refresh = async newCounterpartyId => {
		await getOptions();
		receivable.counterparty_id = newCounterpartyId;
	};

	onMount(() => {
		getReceivable();

		isNewInstance = isBlank($transmitter.receivableId)
		getReceivable()

		if (isBlank($options.possessionKind)) {
			fetchGet('/api/private/possessions/get_kind_options').then(response => ($options = { ...$options, possessionKind: response.options }));
		}

		if (isBlank($options.counterpartyDebtor)) {
			fetchGet('/api/private/counterparties/get_options_for_roles', { possible_roles: ['debtor'] }).then(
				response => ($options = { ...$options, counterpartyDebtor: [{ id: 'new', text: 'Добавить дебитора' }, ...response.options] }),
			);
		}

		if (isBlank($options.valuer)) {
			fetchGet('/api/private/possessions/get_valuer_options').then(response => ($options = { ...$options, valuer: response.options }));
		}

		if (isBlank($options.creditorsRequirements)) {
			fetchGet('/api/private/creditors_requirements/index_as_options').then(response => ($options = { ...$options, creditorsRequirements: response.options }));
		}
	});
</script>

<Select2 {...formFields.counterpartyId} bind:value={receivable.counterparty_id} modify={showModalCounterpartyForm} />
{#if isPresent(subkindOptions)}
	<Select2 {...formFields.kindId} bind:value={receivable.kind_id} />
{/if}
<InputCurrency {...formFields.debtAmount} bind:value={receivable.debt_amount} />
<Textarea {...formFields.note} bind:value={receivable.note} />
<!-- Поступления по требованию -->
<div class="form-group row">
	<label for class="col-form-label col-sm-4 col-lg-3 col-xl-4">Поступления по требованию</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<div class="badge-container">
			{#each toArray(receivable.receipts) as row}
				<div role="button" tabindex="0" on:click|stopPropagation={() => handleEditReceiptItem(row.id)} on:keypress|stopPropagation>
					{formatSum(row.sum) + ' руб. (' + formatDate(row.date_at) + ')'}
				</div>
			{/each}
		</div>
	</div>
</div>
<InputCurrency {...formFields.fundsFromDebtCollection} value={receivable.paid_sum} />
<Input {...formFields.debtContent} bind:value={receivable.debt_content} />
<Input {...formFields.debtJustification} bind:value={receivable.debt_justification} />
<InputCurrency {...formFields.cost} bind:value={receivable.cost} />
<RadioSelect {...formFields.valuer} bind:value={receivable.valuer} />
<Checkbox {...formFields.excludedFromBankruptcy} bind:checked={receivable.excluded_from_bankruptcy} />
{#if receivable.excluded_from_bankruptcy}
	<Input {...formFields.exclusionGrounds} bind:value={receivable.exclusion_grounds} />
{/if}
<Checkbox {...formFields.isPledgeSubject} bind:checked={receivable.is_pledge_subject} />
<!-- Залоговый кредитор -->
{#if isPresent(receivable.is_pledge_subject)}
	<Select2 {...formFields.creditorsRequirementIds} bind:value={receivable.creditors_requirement_ids} />
	<!-- Расходы по продаже залогового имущества -->
	<div class="form-group row">
		<label for class="col-form-label col-sm-4 col-lg-3 col-xl-4">Расходы по продаже залогового имущества</label>
		<div class="col-sm-8 col-lg-9 col-xl-8">
			<div class="badge-container">
				{#each toArray($transmitter.runningCosts) as row}
					<div role="button" tabindex="0" on:click|stopPropagation={() => handleEditRunningCostItem(row.id)} on:keypress|stopPropagation>
						{formatSum(row.sum) + ' руб. (' + truncateString(row.expense_type_text, 18) + ', ' + formatDate(row.date_at) + ')'}
					</div>
				{/each}
			</div>
		</div>
	</div>
{/if}
{#if bankruptIsPerson}
	<!-- Дата покупки (постановки на учёт) -->
	<Datepicker {...formFields.buyDate} bind:value={receivable.buy_date} />
	<!-- Дата продажи (снятия с учёта) -->
	<Datepicker {...formFields.saleDate} bind:value={receivable.sale_date} />
	<!-- Цена продажи (руб.) -->
	<InputCurrency {...formFields.saleCost} bind:value={receivable.sale_cost} />
{/if}
<Textarea {...formFields.note} bind:value={receivable.note}/>
<ScansSet label='Документы', scansSet={receivable.scans_set} disabled={!filledRequiredFields} on:changed={refreshScansSet}/>

<CounterpartyModal {counterpartyId} fixedRoles={['debtor']} on:create={({ detail: newCounterpartyId }) => refresh(newCounterpartyId)} />

<ReceiptModal index="1" bind:this={receiptModal} on:update={handleFormReceiptUpdated} on:delete={handleFormReceiptDeleted} />
